import React from 'react'
import { Row, Col, Button, ButtonGroup } from "react-bootstrap";
import { HashLink } from 'react-router-hash-link'
import "./Footer.css";
import { Link } from 'react-router-dom';

function Footer() {

  return (
    <div className='flex-shrink-0' id='mainFooter'>
      <Row className='UpperFooter justify-content-center justify-content-center'>
        <Col className='text-center-footer col-12 col-lg-5 col-xl-5 col-md-4'>
            <img className='footerLogo' src="./images/Logos/Logo_white.svg" alt="Onbo logo hvid" />
            <p className='footerText'>Med onbo bliver det nemt og bekvemt at være boligejer. Vi giver dig de nødvendige værktøjer til at få overblik over din bolig, samt råd og vejledning til optimering. Vi er Onbo, samlingsstedet for al din ejendomsdata.</p>
        </Col>
        <Col className='col-12 col-sm-5 col-md-2 col-lg-2 col-xl-2 col-md-3 text-center-footer'>
            <h4 >Gå til</h4>
            <ul className='footerLinks'>
              <Link to="/howitworks"><li>Produkt</li></Link>
              <Link to="/ejendomsvurdering"><li>Ejendomsvurdering</li></Link>
              <Link to="/aboutus"><li>Om os</li></Link>
              <Link to="/legal"><li>Vilkår og betingelser</li></Link>
              <Link to="/legal"><li>Cookie- og privatlivspolitik</li></Link>
              <Link to="/legal"><li>Persondatapolitik</li></Link>
            </ul>
        </Col>
        <Col className='col-12 col-sm-5 col-md-2 col-lg-2 col-xl-2 col-md-3 text-center-footer'>
            <h4 >Kontakt</h4>
            <ul className='footerLinks'>
              <Link disabled to="/"><li>FAQ</li></Link>
              <HashLink to='/aboutus#teamSection'>
                <li>Kontaktinformation</li>
              </HashLink>
            </ul>
        </Col>
      </Row>
      <Row className='footerCopyrights justify-content-center align-items-center text-center'>
        <Col className='col-8 col-md-3'>
            <p className='copyText'>Onbo ApS 2022 - All rights reserved</p>
        </Col>
        <Col className='col-8 col-md-3'>
        <ButtonGroup>
          <Button id='footer_facebook' href='https://www.facebook.com/profile.php?id=100090825214052' target='_blank' className='btnLink'><i className="bi bi-facebook"></i></Button>
          <Button id='footer_instagram' href='https://www.instagram.com/onbo_dk/' target='_blank' className='btnLink'><i className="bi bi-instagram"></i></Button>
          <Button id='footer_linkedin' href='https://www.linkedin.com/company/onbo-din-online-bolig/' target='_blank' className='btnLink'><i className="bi bi-linkedin"></i></Button>
          <Button id='footer_mail' href='mailto:kontakt@onbo.dk' target='_blank' className='btnLink'><i className="bi bi-envelope-paper-fill"></i></Button>
        </ButtonGroup>
        </Col>
        <Col className='col-8 col-md-3'>
            <p className='copyText'><Link to="/legal">Privacy Policy </Link> <Link to="/legal"> Terms of use</Link></p>
        </Col>
      </Row>
    </div>
  );
}

export default Footer
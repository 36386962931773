import React from 'react';
import './App.css';
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import "../node_modules/bootstrap-icons/font/bootstrap-icons.css"
import { Route, Routes } from "react-router-dom";
import Frontpage from './components/frontpage/Frontpage';
import Legal from './components/legal/Legal'
import AboutUs from './components/aboutus/AboutUs';
import HowItWorks from './components/howitworks/HowItWorks';
import Signup from './components/Userpage/Signup';
import NotFound404 from './components/404/NotFound404';
import Ejendomsvurdering from './components/ejendomsvurdering/Ejendomsvurdering';
import ComingSoon from './components/ComingSoon';
import ContextWrapper from './components/Userpage/ContextWrapper';
import Createuser from './components/Userpage/Createuser';
import { HelmetProvider } from 'react-helmet-async';
import Footer from './components/Footer';
import Header from './components/Header';


function App() {
  return (
    <HelmetProvider>
      <Header />
        <Routes>          
          <Route exact path="/" element={<Frontpage />}/>
          <Route exact path="/legal" element={<Legal />}/>
          <Route exact path="/aboutus" element={<AboutUs />}/>
          <Route exact path="/howitworks" element={<HowItWorks />}/>
          <Route exact path="/signup" element={<Signup />}/>
          <Route exact path="/ejendomsvurdering" element={<Ejendomsvurdering />}/>
          <Route exact path="/comingsoon" element={<ComingSoon />}/>
          <Route exact path='/minbolig' element={<ContextWrapper />} />
          <Route exact path='/createuser' element={<Createuser />} />
          <Route path="*" element={<NotFound404 />}/>
        </Routes>
      <Footer />
    </HelmetProvider>
  );
}

export default App;
import React from 'react'
import { Row } from 'react-bootstrap'


function ContactComp() {
  return (
    <div className='contactComp-container'>
        <p className='text-center'>Hvis du har spørgsmål eller brug for hjælp, er du altid velkommen til at kontakte os på:</p>
        <Row className='justify-content-center align-items-center text-center contact-text'>
            <div className="col-xl-3 col-lg-4 col-md-5 col-sm-8 col-12 mt-4">
                <a id='cta_mail_btn_1' href="mailto:kontakt@onbo.dk">kontakt@onbo.dk</a>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-5 col-sm-8 col-12 mt-4">
                <a id='cta_tlf_btn_1' href="tel:+4542421130">+45 42 42 11 30</a>
            </div>
        </Row>
    </div>
  )
}

export default ContactComp
import React from 'react'
import { Row } from 'react-bootstrap'
import "./HeroHowWork.css"

function HeroHowWork() {
  return (    
        <>
        <div className='headerNavBackground'></div>    
        <header className='mastheadHowWork'>               
            <Row className="align-items-center">
                <div className="col-1"></div>
                <div className="col-xl-6 col-lg-8 col-sm-10 justify-content-start text-start">
                    <br />
                    <h1 className='herotitleHowWork'>Massere af funktioner <br /> - Gjort nemt for dig!</h1>
                    <p className='heroSubheadingHowWork text-white'>Som boligejer i Danmark, er der meget at holde styr på. <br/> Dette vil vi hos Onbo hjælpe dig med.</p>
                    <br />
                </div>
            </Row>
        </header>
        </>
  )
}
export default HeroHowWork
import React from 'react'
import { Button } from 'react-bootstrap'
import "./Hero404.css"

function Hero404() {
    return (    
        <header className='masthead404'>               
            <div className="row">
                <div className="col-1"></div>
                <div className="col-xl-6 col-lg-8 col-sm-10 justif-content-start text-start">
                    <br />
                    <h1 className='herotitle404'>Hovsa! Noget gik galt..</h1>
                    <br />
                    <p className='heroSubheading404'>Vi kan desværre ikke finde den side du leder efter.</p>
                    <p>Gå tilbage ved at trykke på nedenstående knap!</p>
                    <Button onClick={((e) => window.history.back(e))} className='Btn404'>&nbsp;<i className="bi bi-arrow-left"></i> &nbsp;&nbsp;Tilbage &nbsp;</Button>
                    <br />
                </div>
            </div>
        </header>
    
  )
}

export default Hero404
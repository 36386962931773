import React from 'react'

function ContextWrapper() {
  /* Check for login */



  /* Check for userdata -> otherwise que step by step signup (createuser.jsx) */



  /* If passed and stored in cookies: Fetch data */

  

  return (
    /* Tabs */
    <div>
        
    </div>
  )
}

export default ContextWrapper
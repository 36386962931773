import React, { useState } from 'react'
import "./Createuser.css"
import { Button, Form, Row } from 'react-bootstrap'

function Createuser() {
  /* Check for login and userdata -> If set: return to /minbolig */


  /* Go through signup steps */


  /* Save data in cookie and database. Return to /minbolig */

  const [formPage, setFormPage] = useState(2);
  const [userName, /* setUserName */] = useState("John Doe")
  const [ownershipLenght, setOwnershipLenght] = useState(0)


  const nextPage = () => {
    setFormPage(formPage + 1);
  }
  const lastPage = () => {
    setFormPage(formPage - 1);
  }

  const setOwnershipLenghtFunction = (e) => {
    setOwnershipLenght(e.target.id)
    /* var element = document.getElementById(e.target.id); */
    console.log(ownershipLenght);
  }


  
  return (
    <div className='userCreationContainer'>
      <Row className='justify-content-center'>
        <div style={{height: formPage === 4 ? "40vh" : "60vh", marginTop: formPage === 4 ? "30vh" : "20vh", transition: "all ease 500ms"}} className="userCreationBox col-lg-6 col-xl-6 col-md-8 col-sm-11 col-11">
          { formPage === 1 ? (
            <>
              <Row>
                <p className='ucH1'>Velkommen til Onbo</p>
                <p className='ucH3'>{userName}</p>
              </Row>
              <Row className='mt-5'>
                <p className='ucP'>Før du kan komme i gang med at benytte platformen, har vi først behov for din adresse.</p>
              </Row>
              <Row className='mt-3'>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Control type="email" placeholder="name@example.com" />
                </Form.Group>
              </Row>
            </>
          ) : (
            <>
            </>
          )
          }
          { formPage === 2 ? (
            <>
              <Row>
                <p className='ucH2'>Hvor længe har du været boligejer?</p>
              </Row>
              <Row className='justify-content-center align-items-center mt-3'>
                <div 
                  className="ucRadioBtn"
                  id="1"
                  onClick={setOwnershipLenghtFunction}
                >
                  👶🏼 &nbsp; &nbsp; &nbsp; I under 3 år
                </div>
                <div 
                  className="ucRadioBtn"
                  id="2"
                  onClick={setOwnershipLenghtFunction}
                >
                  📚  &nbsp; &nbsp; &nbsp; I 3 til 8 år
                </div>
                <div 
                  className="ucRadioBtn"
                  id="3"
                  onClick={setOwnershipLenghtFunction}
                >
                  🏅  &nbsp; &nbsp; &nbsp; I mere end 8 år
                  <div className="ucCircle"></div>
                </div>
              </Row>
            </>
          ) : (
            <>
            
            </>
          )
          }
          { formPage === 3 ? (
            <>
              <Row>
                <p className='ucH2'>Rummene i din bolig</p>
                <p className='ucP'>Fortæl os hvilke rum der er i din bolig. Vi har allerede nu tilføjet nogle, som du skal være velkommen til at slette. Du kan altid tilføje flere på et senere tidspunkt.</p>
              </Row>
            </>
          ) : (
            <>
            
            </>
          )
          }
          { formPage === 4 ? (
            <>
              <Row className='ucNarrowpadding'>
                <p className='ucH1'>Perfekt! 🎉</p>
              </Row>
              <Row className='mt-5'>
                <p className='ucP'>Nu er vi helt klar til, at du kan begynde at benytte Onbo! Start ved at trykke på knappen nedenfor.</p>
              </Row>
            </>
          ) : (
            <>
            
            </>
          )
          }
          <section style={{bottom: formPage === 4 ? "35vh" : "22vh", transition: "all ease 500ms"}} className='floatBottom col-lg-5 col-xl-5 col-md-7 col-sm-10 col-10'>
            <Row className='justify-content-center mb-2'>
              { formPage > 1 && formPage !== 4 ? (
                <Button className='userCreationReturnbtn col-4' onClick={lastPage}>Tilbage</Button>
              ) : (
                <></>
              )
              }
                <Button className='userCreationNextbtn col-4' onClick={nextPage}>Næste</Button>
            </Row>
            <Row className='justify-content-center'>
              <div style={{opacity: formPage === 1 ? "100%" : "50%", height: formPage === 1 ? "18px" : "12px", transition: "all ease 500ms"}} className="circleIndicator"></div>
              <div style={{opacity: formPage === 2 ? "100%" : "50%", height: formPage === 2 ? "18px" : "12px", transition: "all ease 500ms"}} className="circleIndicator"></div>
              <div style={{opacity: formPage === 3 ? "100%" : "50%", height: formPage === 3 ? "18px" : "12px", transition: "all ease 500ms"}} className="circleIndicator"></div>
              <div style={{opacity: formPage === 4 ? "100%" : "50%", height: formPage === 4 ? "18px" : "12px", transition: "all ease 500ms"}} className="circleIndicator"></div>
            </Row>
          </section>
        </div>
      </Row>
    </div>
  )
}

export default Createuser
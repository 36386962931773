import React, {useState} from 'react'
import { Button, ButtonGroup, Form } from 'react-bootstrap'
import axios from 'axios';
import Cookies from "universal-cookie";
const cookies = new Cookies();

function LoginForm() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [login, setLogin] = useState(false);
  const [message, setMessage] = useState("");
  const [logginIn, setLogginIn] = useState(false);


  const [validated, setValidated] = useState(false);  

  const handleSubmit = (e) => {
    setLogginIn(true);
    // prevent the form from refreshing the whole page
    e.preventDefault();
    // Check validity form inputs
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }
    setValidated(true);
    // set configurations
    const configuration = {
      method: "post",
      url: "https://mama-mongodb-auth-app.herokuapp.com/login",
      data: {
        email,
        password,
      },
    };
     // make the API call
      axios(configuration)
      .then((result) => {
        setLogin(true);
        // set the cookie
        cookies.set("TOKEN", result.data.token, {
          path: "/",
        });
        cookies.set("EMAIL", result.data.email, {
          path: "/",
        });
        window.location.href = "/minbolig";
        window.location.reload(false);
      })
      .catch((error) => {
        error = new Error();
        setLogginIn(false);
        setMessage("Din adgangskode eller e-mail er forkert. Prøv igen.");
      });
  }

  const testSubmit = (e) => {
    window.location.href = "/minbolig"
  }


  return (
    <Form noValidate validated={validated}  onSubmit={(e)=>handleSubmit(e)}>
      <br />
      <Form.Group controlId="validationCustom01" className="mb-3">
      { logginIn ?
      <div className="loader-container">
        <div className="spinner"></div>
      </div>
      :
      ""
      }      
        <Form.Label>E-mail addresse</Form.Label>
        <Form.Control 
          required
          type="email" 
          placeholder="Indtast e-mail" 
          name="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Form.Control.Feedback type="invalid">
            Venligst indtast din email.
        </Form.Control.Feedback>

      </Form.Group>

      <Form.Group controlId="validationCustom02" className="mb-3">


        <Form.Label>Adgangskode</Form.Label>
        <Form.Control 
          required
          type="password" 
          placeholder="Indtast adgangskode" 
          value={password}
          name="password"
          onChange={(e) => setPassword(e.target.value)}
        />


      </Form.Group>
      <ButtonGroup style={{width: "100%"}} className='justify-content-center align-items-center text-center loginBtn'>


        <Button type='submit' onClick={(e) => testSubmit(e)} style={{width: "100%"}} className='heroBtn1 loginBtn m-0'>
          Log ind
        </Button>


      </ButtonGroup>
      {/* display success message */}
      {login ? (
          <p></p>
        ) : (
          <p className="text-danger">{message}</p>
      )}
    </Form>
  )
}

export default LoginForm
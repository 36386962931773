import React, { useState } from "react";
import "./ComingSoon.css";
import { Col, Form, Row } from "react-bootstrap";
import CardNavigation from "./frontpage/CardNavigation";
import jsonp from "jsonp";

function ComingSoon() {
  const [email, setEmail] = useState("");
  const [subscribed, setSubscribed] = useState(false);
  const [message, setMessage] = useState("");

  const onSubmit = (e) => {
    const emailRegex = /\S+@\S+\.\S+/;
    if (!emailRegex.test(email)) {
      setMessage("Venligst indtast en valid e-mailadresse.");
      return;
    } else {
      setMessage("");
    }
    const url =
      "https://onbo.us21.list-manage.com/subscribe/post?u=ad456043a3b27a4c60b0ee6e2&amp;id=d814478d74&amp;f_id=0096f2e6f0";

    jsonp(`${url}&EMAIL=${email}`, { param: "c" }, (error, data) => {
      if (error) {
        console.error(error);
        return;
      }
      const { msg } = data;

      alert(msg);
    });
    setSubscribed(true);
  };

  return (
    <div className="cs-container">
      <div className="headerNavBackground"></div>

      <div className="masthead-cs">
        <Row className="text-center justify-content-center align-items-center">
          <div className="col-xl-8 col-lg-8 col-md-8 col-sm-10 col-12">
            <h2>Kommer snart</h2>
            <p>
              Vi er glade for din interesse i vores produkt, men det er ikke
              helt klar til dig endnu. Indtil da kan du læse om os og vores
              funktioner, eller holde dig opdateret nedenfor.
              <br />
              <br /> Eller tilmeld dig vores nyhedsbrev, for at blive informeret
              når platformen er klar til dig.
            </p>
            <br />
            <div className="mailInputSubscribe row justify-content-center align-items-center text-center">
              <Form.Control
                style={{
                  display: subscribed ? "none" : "block",
                  borderRadius: subscribed ? "20px" : "20px",
                }}
                type="email"
                name="EMAIL"
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Indtast din e-mailadresse"
                aria-label="Indtast din e-mailadresse"
                className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-11"
              />
              <p className="text-danger">{message}</p>
              <div
                onClick={onSubmit}
                type="button"
                className="subscribeBtn col-xl-2 col-lg-3 col-md-3 col-sm-4 col-12"
                style={{ borderRadius: subscribed ? "20px" : "20px" }}
              >
                {subscribed ? (
                  <>
                    Tak &nbsp;<i className="bi bi-envelope-heart-fill"></i>
                  </>
                ) : (
                  <>
                    Tilmeld &nbsp;<i className="bi bi-envelope-fill"></i>
                  </>
                )}
              </div>
            </div>
            <br />
            <p className="cs-icons pt-3">
              <a
                href="https://www.facebook.com/profile.php?id=100090825214052"
                target="_blank"
                rel="noreferrer"
              >
                <i className="bi bi-facebook"></i>
              </a>
              <a
                href="https://www.instagram.com/onbo_dk/"
                target="_blank"
                rel="noreferrer"
              >
                <i className="bi bi-instagram"></i>
              </a>
              <a
                href="https://www.linkedin.com/company/onbo-din-online-bolig/"
                target="_blank"
                rel="noreferrer"
              >
                <i className="bi bi-linkedin"></i>
              </a>
            </p>
            <br />
          </div>
        </Row>
      </div>

      <Row className="align-items-center justify-content-center">
        <Col className="col-lg-3 col-md-4 col-7">
          <CardNavigation
            /* cardTitle={'Vores funktioner'} */
            /* cardText={'Interesseret i hvad vi tilbyder, og hvordan det hele virker?'} */
            comp_id={"nav_frontPage_btn_functions"}
            cardImage={"./images/Kids/AdobeStock_476583005.jpeg"}
            buttonText={"Vores produkt"}
            cardLink={"/#/howitworks"}
            cardBackground={"/images/Estate/AdobeStock_332678068.jpeg"}
          />
        </Col>
        <Col className="col-lg-3 col-md-4 col-7">
          <CardNavigation
            /* cardTitle={'Mere om os'} */
            /* cardText={'Læs mere om Onbo, teamet bag, og hvordan du kommer i kontakt!'} */
            comp_id={"nav_frontPage_btn_about"}
            cardImage={"./Images/Elder/AdobeStock_484349543.jpeg"}
            buttonText={"Hvem er Onbo?"}
            cardLink={"/#/aboutus"}
            cardBackground={"/images/Estate/AdobeStock_382330271.jpeg"}
          />
        </Col>
      </Row>
    </div>
  );
}

export default ComingSoon;

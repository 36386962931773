import React from 'react'
import { Row } from 'react-bootstrap'
import "./HeroLegal.css"

function HeroLegal() {
  return (    
    <>           
        <header className='mastheadLegal'>               
            <Row className="justify-content-center align-items-center">
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-10 justif-content-start text-start">
                    <h5>Vilkår og betingelser</h5>
                    <h2 className='herotitleLegal'>Se hvordan vi anvender personlige oplysninger</h2>
                    {/* <p className='heroSubheadingLegal'>I dette afsnit vil vi give dig oplysninger om, hvilke personoplysninger vi indsamler om dig og til hvilke formål.</p> */}
                </div>
                <div className="col-xl-4 col-lg-4 col-md-5 d-none d-md-block text-center">
                  <img src="./images/legal.jpg" className='w-75 legalImg' alt="By 8photo on Freepik" />
                </div>
            </Row>
        </header>
    </>
  )
}

export default HeroLegal
import React from 'react'
import "./HeroAbout.css"

function HeroAbout() {
  return (    
        <>
        <div className='headerNavBackground'></div>    
        <header className='mastheadAbout'>               
            <div className="row">
                <div className="col-1"></div>
                <div className="col-xl-6 col-lg-8 col-sm-10 justif-content-start text-start">                    
                    <br />
                    <h1 className='herotitleAbout'>Velkommen til Onbo</h1>
                    <p className='heroSubheadingAbout text-white'>Her kan du læse om hvem Onbo er, samt holdet bagved, der knokler for at gøre det hele muligt</p>
                    <br />
                </div>
            </div>
        </header>   
        </>
  )
}

export default HeroAbout
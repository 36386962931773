import React from 'react'
import { Col, Nav, Row, Tab } from "react-bootstrap";
import TeamMember from './TeamMember';

/* Dette komponent indeholder et andet komponent, TeamMember, som  gennem props bliver tilpasset, til den specifikke medarbejder. */
function Team() {
  return (
    <section>            
        <div className='text-center mt-5'>
            <h2 className=''>Find din kontaktperson</h2>
            <p className='text-muted'>Vi knokler hver dag, for at gøre din oplevelse bedre og bedre. Få kontakt til den, der kan hjælpe dig.</p>
       </div>
        <Row className='justify-content-center pt-5 pb-5'>       
        <div className="col-md-9 col-10">
        <Tab.Container id="left-tabs-example" defaultActiveKey="1">
                    <Row className="legalTabs">
                      <Col xl={3} lg={3} md={3} sm={12}>
                        <Nav variant="pills" className="flex-column aboutPills">
                          <Nav.Item>
                            <Nav.Link eventKey="1">
                            <h4 className="subHeaderLegal">Kunde support</h4>
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="2">
                            <h4 className="subHeaderLegal">Bliv en del af teamet</h4>
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="3">
                            <h4 className="subHeaderLegal">Medier og markedsføring</h4>
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="4">
                            <h4 className="subHeaderLegal">Behandling af data</h4>
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="5">
                            <h4 className="subHeaderLegal">Samarbejdsmuligheder</h4>
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="6">
                            <h4 className="subHeaderLegal">Øvrige</h4>
                            </Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </Col>
                      <Col className='legalTabContent' xl={9} lg={9} md={9} sm={12}>
                        <Tab.Content>
                          <Tab.Pane eventKey="1">
                            <Row className='justify-content-center align-items-center text-center'>
                                <div className="col-xl-4 col-lg-10 col-md-10 col-sm-10 col-10">
                                    <TeamMember 
                                        teamImg={'./images/Team/magnus.jpeg'}
                                        teamName={'Magnus Friis'}
                                        /* teamPosition={'Spørgsmål til produkt'} */
                                        teamLinkedin={'https://www.linkedin.com/in/magnus-friis-christensen-654b02105/'}
                                        teamPhone={'tel:+4529352940'}
                                        teamMail={'mailto:magnus@onbo.dk'}
                                    />
                                </div>
                            </Row>
                          </Tab.Pane>
                          <Tab.Pane eventKey="2">
                            <Row className='justify-content-center align-items-center text-center'>
                                <div className="col-xl-4 col-lg-10 col-md-10 col-sm-10 col-10">
                                    <TeamMember 
                                        teamImg={'./images/Team/mathias.jpeg'}
                                        teamName={'Mathias Wahlfelt'}
                                        /* teamPosition={'Øvrige spørgsmål'} */
                                        teamLinkedin={'https://www.linkedin.com/in/mathias-wahlfelt-61542b150/'}
                                        teamPhone={'tel:+4542421130'}
                                        teamMail={'mailto:mathias@onbo.dk'}
                                    />
                                </div>
                            </Row>
                          </Tab.Pane>
                          <Tab.Pane eventKey="3">
                            <Row className='justify-content-center align-items-center text-center'>
                                <div className="col-xl-4 col-lg-10 col-md-10 col-sm-10 col-10">
                                    <TeamMember 
                                        teamImg={'./images/Team/magnus.jpeg'}
                                        teamName={'Magnus Friis'}
                                        /* teamPosition={'Spørgsmål til produkt'} */
                                        teamLinkedin={'https://www.linkedin.com/in/magnus-friis-christensen-654b02105/'}
                                        teamPhone={'tel:+4529352940'}
                                        teamMail={'mailto:magnus@onbo.dk'}
                                    />
                                </div>
                            </Row>
                          </Tab.Pane>
                          <Tab.Pane eventKey="4">
                            <Row className='justify-content-center align-items-center text-center'>
                                <div className="col-xl-4 col-lg-10 col-md-10 col-sm-10 col-10">
                                    <TeamMember 
                                        teamImg={'./images/Team/mads.jpeg'}
                                        teamName={'Mads Hinge'}
                                        /* teamPosition={'Tekniske spørgsmål'} */
                                        teamLinkedin={'https://www.linkedin.com/in/mads-hinge-åkerlund-7ab595157/'}
                                        teamPhone={'tel:+4521860438'}
                                        teamMail={'mailto:mads@onbo.dk'}
                                    />
                                </div>
                            </Row>
                          </Tab.Pane>
                          <Tab.Pane eventKey="5">
                            <Row className='justify-content-center align-items-center text-center'>
                                <div className="col-xl-4 col-lg-10 col-md-10 col-sm-10 col-10">
                                    <TeamMember 
                                        teamImg={'./images/Team/mathias.jpeg'}
                                        teamName={'Mathias Wahlfelt'}
                                        /* teamPosition={'Øvrige spørgsmål'} */
                                        teamLinkedin={'https://www.linkedin.com/in/mathias-wahlfelt-61542b150/'}
                                        teamPhone={'tel:+4542421130'}
                                        teamMail={'mailto:mathias@onbo.dk'}
                                    />
                                </div>
                            </Row>
                          </Tab.Pane>
                          <Tab.Pane eventKey="6">
                            <Row className='justify-content-center align-items-center text-center'>
                                <div className="col-xl-4 col-lg-10 col-md-10 col-sm-10 col-10">
                                    <TeamMember 
                                        teamImg={'./images/Team/magnus.jpeg'}
                                        teamName={'Magnus Friis'}
                                        /* teamPosition={'Spørgsmål til produkt'} */
                                        teamLinkedin={'https://www.linkedin.com/in/magnus-friis-christensen-654b02105/'}
                                        teamPhone={'tel:+4529352940'}
                                        teamMail={'mailto:magnus@onbo.dk'}
                                    />
                                </div>
                                <div className="col-xl-4 col-lg-10 col-md-10 col-sm-10 col-10">
                                    <TeamMember 
                                        teamImg={'./images/Team/mathias.jpeg'}
                                        teamName={'Mathias Wahlfelt'}
                                        /* teamPosition={'Øvrige spørgsmål'} */
                                        teamLinkedin={'https://www.linkedin.com/in/mathias-wahlfelt-61542b150/'}
                                        teamPhone={'tel:+4542421130'}
                                        teamMail={'mailto:mathias@onbo.dk'}
                                    />
                                </div>
                                <div className="col-xl-4 col-lg-10 col-md-10 col-sm-10 col-10">
                                    <TeamMember 
                                        teamImg={'./images/Team/mads.jpeg'}
                                        teamName={'Mads Hinge'}
                                        /* teamPosition={'Tekniske spørgsmål'} */
                                        teamLinkedin={'https://www.linkedin.com/in/mads-hinge-åkerlund-7ab595157/'}
                                        teamPhone={'tel:+4521860438'}
                                        teamMail={'mailto:mads@onbo.dk'}
                                    />
                                </div>
                            </Row>
                          </Tab.Pane>
                        </Tab.Content>
                      </Col>
                    </Row>
                  </Tab.Container>
                </div>
            </Row>
        <Row>
            <Col className='col-lg-8 mx-auto text-center'><p className='large text-muted'>{/* Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aut eaque, laboriosam veritatis, quos non quis ad perspiciatis, totam corporis ea, alias ut unde. */}</p></Col>
        </Row>            
        </section>
  )
}

export default Team
import React from 'react'
import HeroAbout from './HeroAbout'
import Team from './Team'
import "./Aboutus.css"
import { Button, Row } from 'react-bootstrap'
import CookieModal from '../CookieModal'
import { Helmet } from 'react-helmet-async';
import ContactComp from './ContactComp'
import { Link } from 'react-router-dom'

function AboutUs() {
  return (
    <>
        <Helmet>
            <title>Hvem er vi? | Onbo - Din online bolig</title>
            <meta name='description' content='Vi er en nyopstartet virksomhed, som gerne vil skabe den bedste løsning sammen med vores brugere. Derfor opfordrer vi alle vores brugere at komme med deres feedback, så vi sammen kan få skabt den perfekte plaform for samlingssted af al ens ejendomsdata.' />
        </Helmet>
        <CookieModal />
        <HeroAbout />
        <div className='hiw-container mb-5'>
            <Row className='justify-content-center align-items-center text-center'>
            <h3>VI HAR DIG I FOKUS</h3>
            <br />
            <br />
            <br />
            <p className='w-75'>
                Vi er en nyopstartet virksomhed, som gerne vil skabe den bedste løsning sammen med vores brugere. Derfor opfordrer vi alle til at komme med deres feedback, så vi sammen kan få skabt den perfekte plaform for samlingssted af al ens ejendomsdata.
            </p>
            </Row>
        </div>
        <div className="row story">
            <div className="col-xl-1 col-lg-1 col-2"></div>
            <div className="col-xl-4 col-lg-4 col-8">
                <h6>Vores værdier</h6>
                <h3>Onbo - din online bolig</h3>
                <p>Vi er en virksomhed, der er dedikeret til at hjælpe boligejere med at få mest muligt ud af deres hjem. Vores mission er at skabe overblik omkring din bolig og give dig de værktøjer du har brug for, til at tage kontrollen over dit hjem. Vi tror på, at det er vigtigt at de penge og den tid man investere i sin ejendom, vil få fremtidige værdi for dig og nye ejere. Derfor har vi skabt en platform, der hjælper dig med at få overblik over din bolig, identificere problemområder og finde måder at spare penge på og hæve den fremtidige salgsværdi. Vi mener, at alle boligejere fortjener et hjem, der er sundt, sikkert og økonomisk bæredygtigt.</p>
            </div>
            <div className="col-xl-1 col-lg-1 col-2"></div>
            <div className="col-lg-6 d-none d-lg-block"></div>
        </div>
        <div className="row story2 mb-5">
            <div className="col-lg-6 d-none d-lg-block"></div>
            <div className="col-xl-1 col-lg-1 col-2"></div>
            <div className="col-xl-4 col-lg-4 col-8">
                <h6>Onbos Historie</h6>
                <h3>Vores forretning</h3>
                <p>Onbo blev grundlagt i 2022, da Mathias og Mads opdagede, at mange boligejere mangler et centralt sted til at opbevare alle deres ejendomsoplysninger. De besluttede at skabe en platform, der samler alt ejendomsdata omkring boligen et sted, så boligejere kan holde styr på vedligeholdelse, budgetter og andre vigtige oplysninger. Siden da har vi arbejdet hårdt på at udvikle og forbedre vores tjenester for at sikre, at vi altid lever op til vores mission.</p>
                <Button id='nav_about_btn_functions' className='heroBtn1'><Link style={{color: "white", textDecoration: "none"}} to="/howitworks">Læs mere her</Link></Button>
            </div>
            <div className="col-xl-1 col-lg-1 col-2"></div>        
        </div>
        <div id='teamSection' className='pt-5 m-3'>
            <Team />
        </div>        
        <div>
            <ContactComp />
        </div>        
        
    </>
  )
}

export default AboutUs
import React, { useEffect, useState } from "react";
import { Button, Container, Nav, Navbar, Offcanvas } from "react-bootstrap";
import "./header.css";
import LoginForm from "./LoginForm";
import Cookies from "universal-cookie";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
const cookies = new Cookies();


function Header() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
   
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, [scrolled]);

  const handleScroll = () => {
    if (window.scrollY > 5) {
      setScrolled(true);
    } else {
      if(window.innerWidth <= 991) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    }
  };

  const handleResize = () => {
    if (window.innerWidth <= 991) {
      setScrolled(true);
    } else {
      if(window.scrollY > 5) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    }
  };



  function collapseToggler() {
    var element = document.getElementById("collapseTogglerId");
    element.classList.toggle("active");
  }

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  /* const handleShow = () => setShow(true); */


    /* User logout */  
    const [loggedIn, setloggedIn] = useState(false);
    useEffect(() => {
      const email = cookies.get("EMAIL");
      if(email) {
        setloggedIn(true);
      } else {
        setloggedIn(false);
      }
    }, [loggedIn]);

    const logout = () => {
    // destroy the cookie
    cookies.remove("TOKEN", { path: "/" });
    cookies.remove("EMAIL", { path: "/" });
    cookies.remove("userDataCookie", { path: "/" });
    cookies.remove("334adf6399a4926c21ac6626880ae1f3", { path: "/" });
    cookies.remove("6a3a4e55f06f9765e773a6e683718629", { path: "/" });
    cookies.remove("ed23b9f3667352a6a1125bc22364914a", { path: "/" });
    cookies.remove("todoCookie", { path: "/" });
    // redirect user to the landing page
    window.location.href = "/";
    }
      
    const location = useLocation()    
    const [frontpage, setFrontpage] = useState(false)
    const [product, setProduct] = useState(false)
    const [ejendomsvurdering, setEjendomsvurdering] = useState(false)
    const [aboutus, setAboutus] = useState(false)
    const [signup, setSignup] = useState(false)


    useEffect(() => {
      if(location.pathname === "/") {
        setFrontpage(true)
      } else {
        setFrontpage(false)
      }
      if(location.pathname === "/howitworks") {
        setProduct(true)
      } else {
        setProduct(false)
      }
      if(location.pathname === "/ejendomsvurdering") {
        setEjendomsvurdering(true)
      } else {
        setEjendomsvurdering(false)
      }
      if(location.pathname === "/aboutus") {
        setAboutus(true)
      } else {
        setAboutus(false)
      }
      if(location.pathname === "/signup" || location.pathname === "/comingsoon") {
        setSignup(true)
      } else {
        setSignup(false)
      }
    }, [location.pathname])

    

  return (
    <Navbar id="mainNavbar" variant="dark" collapseOnSelect expand="lg" fixed="top"
            style={{transition: "0.2s ease", backgroundColor: scrolled ? "white" : "white", boxShadow: scrolled ? "10px 10px 10px rgba(0,0,0,0.1)" : "none"}}>
      <Container className="navContainer">
        <Navbar.Brand href="/" style={{transition: "0.5s ease",  color: scrolled ? "black" : "white"  }}>
          <img className="brandLogo" src="./images/Logos/Logo_black.svg" alt="Onbo logo sort" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav">
          <div id="collapseTogglerId" className="hamburger" onClick={collapseToggler}>
            <span style={{transition: "0.2s ease", backgroundColor: scrolled ? "black" : "black" }}
                  className="line"></span>
            <span style={{transition: "0.2s ease", backgroundColor: scrolled ? "black" : "black" }}
                  className="line"></span>
            <span style={{transition: "0.2s ease", backgroundColor: scrolled ? "black" : "black" }}
                  className="line"></span>
          </div>
        </Navbar.Toggle>
        <Navbar.Collapse id="responsive-navbar-nav">

          <Nav className="me-auto">
            <Nav className="navItemColo homeBtn" onClick={collapseToggler} style={{paddingRight: "20px", transition: "0.2s ease", color: scrolled ? "black" : "black"}} to="/">
              <Link style={{color: "black", textDecoration: "none"}} to="/">
              <i style={{transition: "0.5s ease", color: frontpage ? "blue" : "transparent"}} className="bi bi-dot"></i>
              Forside
              </Link>
            </Nav>
            <Nav className="navItemColor align-items-center" onClick={collapseToggler} style={{transition: "0.2s ease", color: scrolled ? "black" : "black"  }}>
              <Link style={{color: "black", textDecoration: "none"}} to="/howitworks">
              <i style={{transition: "0.5s ease", color: product ? "blue" : "transparent"}} className="bi bi-dot"></i>
              Produkt
              </Link>
            </Nav>              
            <Nav className="navItemColor align-items-center" onClick={collapseToggler} style={{transition: "0.2s ease", color: scrolled ? "black" : "black"  }}>
              <Link style={{color: "black", textDecoration: "none"}} to="/ejendomsvurdering">
              <i style={{transition: "0.5s ease", color: ejendomsvurdering ? "blue" : "transparent"}} className="bi bi-dot"></i>
              Ejendomsvurdering
              </Link>
            </Nav>              
            <Nav className="navItemColor align-items-center" onClick={collapseToggler} style={{transition: "0.2s ease",  color: scrolled ? "black" : "black"  }}>
              <Link style={{color: "black", textDecoration: "none"}} to="/aboutus">
              <i style={{transition: "0.5s ease", color: aboutus ? "blue" : "transparent"}} className="bi bi-dot"></i>
              Om os
              </Link>
            </Nav>
          </Nav>

          <Nav >
            {loggedIn ? (
                <Nav.Link className="myHouseBtn" onClick={collapseToggler} style={{transition: "0.2s ease", color: scrolled ? "white" : "white"  }} >
                  <Link style={{color: "white", textDecoration: "none"}} to="/dashboard">
                    MinBolig
                  </Link>
                </Nav.Link>
              ) : (
                <Nav.Link style={{display: "none"}}></Nav.Link>
            )}            
            {loggedIn ? (
                <Nav.Link className="loginBtnHeader" onClick={() => logout()} style={{transition: "0.2s ease", color: scrolled ? "black" : "black", borderColor: scrolled ? "black" : "black", paddingLeft: "20px", paddingRight: "20px"  }} 
                >LOG UD</Nav.Link>
              ) : (
                <>
                <Nav className="navItemColor align-items-center" onClick={collapseToggler} style={{transition: "0.2s ease",  color: scrolled ? "black" : "black", paddingRight: "10px"  }}>
                  {/* <Link style={{color: "black", textDecoration: "none"}} to="/signup"> */}
                  <Link style={{color: "black", textDecoration: "none"}} to="/comingsoon">
                  <i style={{transition: "0.5s ease", color: signup ? "blue" : "transparent"}} className="bi bi-dot"></i>
                  Opret bruger
                  </Link>
                </Nav>

                {/* <Nav.Link className="loginBtnHeader" onClick={handleShow} style={{transition: "0.2s ease", color: scrolled ? "white" : "black", borderColor: scrolled ? "#0C2078" : "white", backgroundColor: scrolled ? "#0C2078" : "white"  }} 
                >&nbsp;&nbsp;&nbsp;<i className="bi bi-person-circle"></i> &nbsp;LOG IND&nbsp;&nbsp;&nbsp;</Nav.Link> */}
                <Nav.Link className="loginBtnHeader" to="/comingsoon" style={{transition: "0.2s ease", color: scrolled ? "white" : "black", borderColor: scrolled ? "#0C2078" : "white", backgroundColor: scrolled ? "#0C2078" : "white"  }} 
                >&nbsp;&nbsp;&nbsp;<i className="bi bi-person-circle"></i> &nbsp;LOG IND&nbsp;&nbsp;&nbsp;</Nav.Link>
                </>
            )}            
          </Nav>
        </Navbar.Collapse>
      </Container>

      <Offcanvas className="loginOffcanvas" placement="end" show={show} onHide={handleClose} >
        <Button onClick={handleClose} className="closeBtn"><i className="bi bi-x-lg"></i></Button>       
        <Offcanvas.Body className="loginOffcanvasBody">
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-8">
              <Offcanvas.Header>
                <Offcanvas.Title>Log ind eller opret bruger</Offcanvas.Title>
              </Offcanvas.Header>
              <LoginForm /> 
              <Button id='cro_signup_btn_1' onClick={handleClose} style={{width: "100%"}} className='signupBtnLogin m-0' type="submit">
                <Link style={{color: "black", textDecoration: "none"}} to="/signup">
                Opret
                </Link>
              </Button>
            </div>
            <div  className="col-xl-6 col-lg-4 d-none d-lg-block sideImg">

            </div>
          </div>            
        </Offcanvas.Body>
      </Offcanvas>
    </Navbar>
  );
}

export default Header;

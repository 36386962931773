import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import CardNavigation from "./CardNavigation";
import Herocontainer from "./Herocontainer";
import "./Frontpage.css";
import CookieModal from "../CookieModal";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

function Frontpage() {
  return (
    <>
      <Helmet>
        <title>Onbo - Din online bolig</title>
        <meta
          name="description"
          content="Med Onbo kan du nemt holde styr på vedligeholdelse, reparationer, og alle andre vigtige oplysninger om din bolig. Vi tilbyder dig en omfattende platform, der hjælper dig med at holde overblik på alt fra regninger til servitutter, så du altid er i kontrol."
        />
        <meta
          name="keywords"
          content="ejendomsoplysninger, onbo, din online bolig, online bolig, ejendomsdata, oplysninger om bolig, hjælp til bolig, boligejer, boligrådgivning, bolig rådgivning"
        />
      </Helmet>
      <CookieModal />
      <Herocontainer />

      <Row className="align-items-center justify-content-center">
        <Col className="col-lg-3 col-md-4 col-7">
          <CardNavigation
            comp_id={"nav_frontPage_btn_functions"}
            cardImage={"./Images/Kids/AdobeStock_476583005.jpeg"}
            buttonText={"Vores produkt"}
            cardLink={"/howitworks"}
            cardBackground={"/images/Couple/AdobeStock_224071450.jpeg"}
          />
        </Col>
        <Col className="col-lg-3 col-md-4 col-7">
          <CardNavigation
            comp_id={"nav_frontPage_btn_pp"}
            cardImage={"./Images/Estate/AdobeStock_422961960.jpeg"}
            buttonText={"Sikkerhed"}
            cardLink={"/legal"}
            cardBackground={"/images/Kids/AdobeStock_411799972.jpeg"}
          />
        </Col>
        <Col className="col-lg-3 col-md-4 col-7">
          <CardNavigation
            comp_id={"nav_frontPage_btn_about"}
            cardImage={"./Images/Elder/AdobeStock_484349543.jpeg"}
            buttonText={"Hvem er Onbo?"}
            cardLink={"/aboutus"}
            cardBackground={"/images/Estate/AdobeStock_382330271.jpeg"}
          />
        </Col>
      </Row>

      <br id="scrollInfo" />
      <br />
      <br />
      <br />

      <div className="help row text-start justify-content-center">
        <div className="col-lg-9">
          <div className="row justify-content-center align-items-center">
            <div className="col-md-6 col-sm-8 col-xs-6 infoTextContainer1">
              <h5>Vedligeholdelsesplaner</h5>
              <h3>
                Få hjælp til at lægge en plan, for vedligehold af din bolig
              </h3>
              <p>
                Noget som alle boligejere har tilfælles, er, at vi ønsker at
                vores bolig er i god stand. Med Onbo's vedligeholdelsesplan kan
                du forebygge skader og forlænge levetiden på din bolig, samt
                forudsige eventuelle omkostninger. En plan kan omfatte alt fra
                regelmæssig rengøring og vedligeholdelse af tag, facade og
                vinduer til service af varmeanlæg, vandinstallationer og
                elinstallationer.
              </p>
              <Button
                id="CTA_frontPage_btn_vedligehold_signup"
                className="heroBtn1 BtnresponsiveLeft"
              >
                <Link to="/comingsoon">Start i dag</Link>
              </Button>
            </div>
            <div className="col-md-6 col-sm-8 col-xs-6 infoTextContainer2">
              <img
                src="./images/Infographics/infographic2.jpeg"
                className="infographicImg rounded w-100 img-fluid"
                alt="Infografik vedligeholdelseplan funktion"
              />
            </div>
          </div>
        </div>
      </div>

      <div id="calcScroll" className="div"></div>
      <br />
      <br />
      <br />
      <br />

      <Row className="align-items-center justify-content-center p-0">
        <div id="calc">
          <div className="textDataWrapper text-center text-white pt-4">
            <h3>Nye offentlige ejendomsvurderinger er klar!</h3>
            <h6>Læs mere om deres betydning for dig her.</h6>
            <br />
            <Button
              id="nav_frontPage_btn_calc"
              style={{
                backgroundColor: "white",
                color: "blue",
                padding: "2px 20px 2px 20px",
              }}
            >
              <Link
                style={{ color: "blue", textDecoration: "none" }}
                to="/ejendomsvurdering"
              >
                Klik her
              </Link>
            </Button>
          </div>
        </div>
      </Row>

      <br />
      <br />
      <br />
      <br />

      <div className="help row text-start justify-content-center">
        <div className="col-lg-9">
          <div className="row justify-content-center align-items-center">
            <div className="col-md-6 col-sm-8 col-xs-6 infoTextContainer1">
              <img
                src="./images/Infographics/infographic.jpeg"
                className="infographicImg rounded w-100 img-fluid"
                alt="Infografik bolig dokumenter funktion"
              />
            </div>

            <div className="col-md-6 col-sm-8 col-xs-6 infoTextContainer2">
              <h5 className="h5responsiveRight">Boligdokumenter</h5>
              <h3>Undgå ringbindet og digitaliser alt din ejendomsdata</h3>
              <p>
                Slip for at lede efter alle dine ejendomsoplysninger og opbevar
                din ejendomdata sikkert og samlet ét sted.
              </p>
              {/* <Button id='CTA_frontPage_btn_documents_signup' className='heroBtn1 BtnresponsiveRight'><Link to="/signup">Prøv det nu</Link></Button> */}
              <Button
                id="CTA_frontPage_btn_documents_signup"
                className="heroBtn1 BtnresponsiveRight"
              >
                <Link to="/comingsoon">Prøv det nu</Link>
              </Button>
            </div>
          </div>
        </div>
      </div>

      <br />
      <br />
      <br />
      <br />
      <br />
    </>
  );
}

export default Frontpage;

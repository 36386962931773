import React, {useState} from 'react'
import { Button, Form } from 'react-bootstrap'
import axios from 'axios';
import "./SignupForm.css"
import Cookies from "universal-cookie";
import { Link } from 'react-router-dom';
const cookies = new Cookies();

function SignupForm() {
  /* Inputs */
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [passwordAuth, setPasswordAuth] = useState("");
  const [adress] = useState("");
  const [phone] = useState("");
  const [adress_id] = useState("");
/*   const [adress, setAdress] = useState(""); */

  const [register, setRegister] = useState(false);
  const [message, setMessage] = useState("");
  const [passwordMessage, setPasswordMessage] = useState("");



  /* Submit to database */
  const [validated, setValidated] = useState(false);  

  const handleSubmit = (e) => {
    // prevent the form from refreshing the whole page
    e.preventDefault();
    // Check validity form inputs
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
    setValidated(true);
    if (password === passwordAuth) {
      // set configurations
    const configuration = {
      method: "post",
      url: "https://mama-mongodb-auth-app.herokuapp.com/register",
      data: {
        email,
        name,
        password,
        adress,
        phone,
        adress_id,
      },
    };
    axios(configuration)
      .then((result) => {
        setRegister(true);
        cookies.set("TOKEN", result.data.token, {
          path: "/",
        });
        cookies.set("EMAIL", email, {
          path: "/",
        });
        window.location.href = "/createuser"
      })
      .catch((error) => {
        error = new Error();
        setMessage("Der findes allerede en bruger med denne e-mail.");
      });
    } else {
      setPasswordMessage("De indtastede adgangskoder er ikke ens");
      e.preventDefault();
      e.stopPropagation();
    }
  }
  }
  const testSubmit = (e) => {
    window.location.href = "/createuser"
  }

  
  return (
    <Form  noValidate validated={validated} onSubmit={(e)=>handleSubmit(e)} className='SignupFormContainer'>
      <Form.Group
      controlId="validationCustom01"
      >
        <h5>Min Bolig - Din genvej til at være bedre boligejer</h5>
        <h2>Opret bruger</h2>
        <br />

        {/* ---------  Email  --------- */}
        <Form.Label>E-mail address</Form.Label>
        <Form.Control 
          className='signupInput'
          required
          type="email" 
          placeholder="Indtast e-mail"           
          name="email"
          onChange={(e) => setEmail(e.target.value)}
          value={email}  
        />
        <p className="text-danger">{message}</p>
        <Form.Control.Feedback type="invalid">
            Venligst indtast din E-mail adresse.
        </Form.Control.Feedback>

      </Form.Group>
      <Form.Group 
      className="mb-3"
      controlId="validationCustom02"
      >
        {/* ---------  Name  --------- */}
        <Form.Label className='pt-2'>Navn</Form.Label>
        <Form.Control 
          className='signupInput'
          required
          type="text" 
          placeholder="Indtast dit fulde navn" 
          name="name"
          onChange={(e) => setName(e.target.value)}
          value={name}       
        />
        <Form.Control.Feedback type="invalid">
            Venligst indtast dit fulde navn.
        </Form.Control.Feedback>

        <Form.Text className="text-muted"></Form.Text>
        <br />       
      </Form.Group>


      <Form.Group
      controlId="validationCustom03"
      >
        {/* ---------  Password  --------- */}
        <Form.Label>Adgangskode</Form.Label>
        <Form.Control 
          className='signupInput'
          required
          type="password" 
          placeholder="Indtast adgangskode" 
          name="password"
          onChange={(e) => setPassword(e.target.value)}
          value={password}
        />
      <Form.Control.Feedback type="invalid">
            Venligst vælg en adgangskode.
      </Form.Control.Feedback>
      </Form.Group>

      <Form.Group 
      className="mb-3"
      controlId="validationCustom04"
      >
        {/* ---------  Second password auth  --------- */}
        <Form.Label></Form.Label>
        <Form.Control 
            className='signupInput'
            required
            type="password"  
            placeholder="Gentag adgangskode" 
            name="passwordAuth"
            onChange={(e) => setPasswordAuth(e.target.value)}
            value={passwordAuth}              
          />
          <p className="text-danger">{passwordMessage}</p>
        <Form.Control.Feedback type="invalid">
            Venligst gentag din valgte adgangskode.
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group 
      className="mb-3" 
      controlId="validationCustom06"
      >
        <Form.Check 
        required 
        type="checkbox" 
        label="Accepter vilkår og betingelser" 
        feedback="For at oprette en bruger ved Onbo, skal du acceptere vores vilkår og betingelser."
        feedbackType="invalid"
        />    
        <p className='pt-2'>Du kan læse vores privatlivspolitik om dine vilkår og betingelser<Link to="/legal">her</Link></p>
      </Form.Group>
      

      <Button id='cro_signup_btn_2' onClick={(e)=>testSubmit(e)} className='signupBtn' type="submit">
        Opret Bruger
      </Button>
      {/* display success message */}
      {register ? (
          <p className="text-success">Bruger oprettet succesfuldt</p>
        ) : (
          <p></p>
        )}
    </Form>
  )
}

export default SignupForm
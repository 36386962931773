import React from 'react'
import { Button, Row } from 'react-bootstrap'
import "./Herocontainer.css"
import { Link } from 'react-router-dom'


function Herocontainer() {
  return (
    <>
        <div className='headerNavBackground'></div>
        <header className='masthead'>
            <Row className="row containerHero align-items-center justify-content-center">
                <div className="col-xl-1 col-lg-1 col-0"></div>
                <div className="col-xl-5 col-lg-5 col-md-8 col-sm-10 col-11 justify-content-start text-start">
                    <h1 className='onboHeading'>Din Online Bolig</h1>
                    <p className='noMatterP'>{/* Uanset om du bor i <br /> {text} */}Alt information om din bolig<br />- samlet et sted</p>
                    <p className='heroSubheading'>Med Onbo kan du nemt holde styr på vedligeholdelse, renoveringer, og alle andre vigtige oplysninger om din bolig. Vi tilbyder dig en omfattende platform, der hjælper dig med at holde overblik over alt fra vedligehold og dokumentation til økonomi og forbedringer vedrørende din bolig, så du altid er i kontrol.</p>
                    <br />
                    <Button id='cta_createuser_btn_1' className='heroBtn1'><Link to="/howitworks">Læs mere</Link></Button>
                </div>
                <div className="col-xl-5 col-lg-5 col-8 text-center">
                    <img className='fluid-img imgHouseplan' src="./images/floorPlan/Front_img.svg" alt="Forside billede Onbo" />
                </div>
            </Row>
        </header>
    </>
  )
}

export default Herocontainer
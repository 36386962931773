import React from 'react'
import { Col, Nav, Row, Tab } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import HeroLegal from './HeroLegal'
import "./Legal.css"

function Legal() {
  return (
    <>
        <Helmet>
          <title>Vilkår og betingelser | Onbo - Din online bolig</title>
          <meta name='description' content='Se hvordan vi anvender personlige oplysninger. Udbyder af tjenesten og dataansvarlig for behandling af personoplysninger er Onbo ApS' />
        </Helmet>
        <HeroLegal />
        <div className="row justify-content-center">            
            <div className="col-md-8 col-10">
                <br /><br />
                <p>Disse er senest blevet opdateret 21. marts 2023.</p>
                <p>Udbyder af tjenesten og dataansvarlig for behandlingen af personoplysninger er:</p>
                <ul className='liFirminfo'>
                    <li><i className="bi bi-building"></i>&nbsp;&nbsp;&nbsp; Onbo ApS</li>
                    <li><i className="bi bi-patch-check"></i>&nbsp;&nbsp;&nbsp; CVR: 43 79 08 89</li>
                    <li><i className="bi bi-compass"></i>&nbsp;&nbsp;&nbsp; Universitetsbyen 14, 8000 Aarhus</li>
                    <li type="button" onClick={() => window.location = 'tel:21860438'}><i className="bi bi-telephone-outbound"></i>&nbsp;&nbsp;&nbsp; Tlf: 21 86 04 38</li>
                    <li type="button" onClick={() => window.location = 'mailto:kontakt@onbo.dk'}><i className="bi bi-envelope-heart"></i>&nbsp;&nbsp;&nbsp; Email: kontakt@onbo.dk</li>
                </ul>
                <div className="pContainerLegal">
                  <p>Brugerprofil: Brugere kan oprette en profil, hvor de angiver deres stamdata, herunder navn, adresse, telefonnummer, e-mail m.m., samt boligdata og opgavedata (opgavebeskrivelse/projektbeskrivelse). Stamdata, boligdata og opgavedata kan kun ses af Onbo ApS og dets samarbejdspartnere.</p>
                  <p>Brugere kan uploade og dele eget materiale og oplysninger med Onbo ApS og de tilmeldte samarbejdspartnere. Denne del indeholder bl.a. et system, hvor brugeren har mulighed for at indhente tilbud på opgaver, på baggrund af den data, som det har oplyst om deres bolig.</p>
                  <p>Profilen er personlig, og brugeren må kun oprette én profil. Brugeren må ikke opgive en anden persons e-mail eller udgive sig for at være en anden.</p>
                  <p>Råd og vejledning: Brugere kan søge råd og vejledning om drift og vedligeholdelse af deres bolig. Disse råd er vejledende, og hverken vi eller vores samarbejdspartnere kan stilles til ansvar for forhold, der måtte indtræffe som følge af råd og vejledning, der er modtaget.</p>
                  <p>Beregning af værdi, vedligeholdelse og renovering af boliger er kompliceret, derfor stiller vi en række vejledende værktøjer til rådighed på brugerprofilen. Værktøjerne er baserede på historiske handler, gennemsnitssalgspriser i lokalområdet, boligdata og en række andre forhold. Konkrete boliger kan pris-, renoverings-, og vedligeholdelsesmæssigt afvige markant. Du bør derfor aldrig kun tage værktøjerne på brugerprofilen i betragtning, søg altid anden bistand, før du disponerer.</p>
                  <br /><br />

                  <h3 className='herotitleLegal'><i className="bi bi-dot"></i>Behandlingsaktiviteter</h3>

                  <Tab.Container id="left-tabs-example" defaultActiveKey="1">
                    <Row className="legalTabs">
                      <Col xl={4} lg={4} md={4} sm={12}>
                        <Nav variant="pills" className="flex-column">
                          <Nav.Item>
                            <Nav.Link eventKey="1">
                            <h4 className="subHeaderLegal">Deling af indhold og ansvar for eget indhold del 1</h4>
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="2">
                            <h4 className="subHeaderLegal">Deling af indhold og ansvar for eget indhold del 2</h4>
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="3">
                            <h4 className="subHeaderLegal">Oplysninger til brugeren om tjenesten</h4>
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="4">
                            <h4 className="subHeaderLegal">Rettigheder til tjenesten og indhold på sitet</h4>
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="5">
                            <h4 className="subHeaderLegal">Brug af oplysninger og materiale</h4>
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="6">
                            <h4 className="subHeaderLegal">Sletning af brugerens profil</h4>
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="7">
                            <h4 className="subHeaderLegal">Ændring af disse vilkår</h4>
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="8">
                            <h4 className="subHeaderLegal">Overdragelse</h4>
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="9">
                            <h4 className="subHeaderLegal">Lovvalg og værneting</h4>
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="10">
                            <h4 className="subHeaderLegal">Cookiepolitikker</h4>
                            </Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </Col>
                      <Col className='legalTabContent' xl={8} lg={8} md={8} sm={12}>
                        <Tab.Content>
                          <Tab.Pane eventKey="1">
                            <p>Brugeren har via tjenesten mulighed for at uploade sit eget materiale f.eks. tilstandsrapport, tegninger, fakturaer, billeder mv., og dele dette indhold med Onbo ApS og samarbejdspartnere.</p>
                            <p>Uploader brugeren materiale på tjenesten skal brugeren sikre at andres rettigheder ikke krænkes, herunder andres ophavsrettigheder og varemærkerettigheder. Uploader og deler brugeren materiale andre har lavet, skal brugerne sikre sig, at brugeren ejer de nødvendige rettigheder til det materiale, som brugeren uploader eller har fået konkret samtykke fra rettighedshaveren til at uploade og dele rettighedshaverens beskyttede materiale.</p>
                            <p>Brugeren må ikke uden konkret samtykke fra rettighedshaveren uploade følgende materiale:</p>
                            <ul>
                              <li>Indscannede sider fra bøger og blade</li>
                              <li>Dokumenter og andet indhold fundet på andre websites</li>
                              <li>Fotos, som brugeren ikke selv har taget</li>
                            </ul>                  
                            <p>Brugeren har selv rådighed over og er selv ansvarlig for det indhold, som brugeren vælger at uploade og dele med andre.</p>
                            <p>Onbo ApS påtager sig intet ansvar for brugerens materiale og foretager ingen form for clearing af rettigheder.</p>
                            <p>Brugeren indestår selv for lovligheden af det materiale, som brugeren uploader og deler med andre, herunder at brugen af materialet ikke krænker andres rettigheder fx ophavsrettigheder, varemærkerettigheder eller rettigheder i henhold til Databeskyttelseslovgivningen. Skulle Onbo ApS blive mødt af et erstatningskrav fra tredjemand, fordi brugeren ved upload og deling af materialet har krænket andres rettigheder, hæfter brugeren overfor Onbo ApS for sådanne erstatningskrav og de omkostninger dette måtte medføre, og risikerer at blive mødt med erstatningskrav.</p>
                          </Tab.Pane>
                          <Tab.Pane eventKey="2">
                            <p>Brugeren er berettiget til at bruge den tilgængelige funktionalitet på tjenesten. Onbo ApS forbeholder sig ret til løbende at ændre funktionaliteten på tjenesten. Tjenesten er primært til brug for brugerens forespørgsel på renovering og vedligeholdelse af bolig og samarbejdspartneres tilbud herpå.</p>
                            <p>Hvis materialet, som brugeren uploader, krænker andres rettigheder f.eks. ophavs- eller varemærkerettigheder, eller hvis materialet udgør en krænkelse af anden lovgivning, forbeholder vi os retten til at fjerne/slette sådant materiale efter eget skøn og uden forudgående advarsel.</p>
                            <p>Brugeren skal holde almindelig ”etikette”, herunder undgå tilkendegivelser eller upload af stødende materiale med f.eks. racistisk, injurierende eller pornografisk indhold, ligesom der skal benyttes acceptabelt sprogbrug. Mobning og chikane accepteres ikke.</p>
                            <p>Onbo ApS forbeholder sig ret til uden yderligere varsel at fjerne indhold og information, som vurderes at overtræde disse vilkår eller relevant lovgivning. Ved grove overtrædelser eller gentagne overtrædelser af disse vilkår forbeholder vi os retten til at hindre brugerens adgang til tjenesten og til at slette brugerens profil.</p>
                          </Tab.Pane>
                          <Tab.Pane eventKey="3">
                            <p>Brugeren accepterer at Onbo ApS og samarbejdspartnere på tjenesten kan sende meddelelser eller kontakte brugeren via e-mail, telefon eller sms. Der kan endvidere på tjenesten forekomme annoncer og markedsføringsmateriale fra os og vores samarbejdspartnere.</p>
                            <p>Onbo ApS anvender brugerens e-mail, telefonnummer og sms til at informere om at der er modtaget besked fra en samarbejdspartner, vigtig information om tjenesten: f.eks. oplysninger om sikkerhedsmæssige forhold, væsentlige ændringer af disse vilkår, driftsoplysninger, samt ved nye eller ændrede funktioner i tjenesten m.v.</p>
                            <p>Brugeren accepterer, at Onbo ApS henter de generelle informationer om brugerens ejendom; herunder data fra kommuner, Danmarks Statistik, OIS. Det omfatter bl.a. oplysninger om BBR. BBR-oplysninger hviler bl.a. på indberetninger fra ejerne af ejendommene og kan derfor være fejlbehæftede.</p>
                            <p>Tjenesten er hostet hos Simply.com, og benytter MongoDB som database til opbevaring af alt data om brugeren og dennes boligprofil.</p>
                          </Tab.Pane>
                          <Tab.Pane eventKey="4">
                            <p>Onbo ApS erhverver ingen rettigheder til det materiale, som brugeren vælger at uploade, bortset fra en ikke-eksklusiv tidsubegrænset brugsret, herunder retten til at gøre materialet tilgængeligt for andre relevante parter gennem tjenesten eller på andre måder.</p>
                            <p>Som uploader af og rettighedshaver til materiale på tjenesten accepterer brugeren at dem, der får adgang til materialet, kan gøre sig bekendt med informationen, herunder læse og downloade materialet og bruge materialet til egne private formål, samt at Onbo ApS og vores samarbejdspartnere kan bruge oplysningerne erhvervsmæssigt, herunder til opfyldelse af brugerens forespørgsler i forbindelse med indhentelse af tilbud.</p>
                            <p>Brugeren må anvende det materiale, der af Onbo ApS og andre stilles til rådighed på tjenesten, til egne private formål. Brugeren må ikke give andre adgang til at bruge tjenesten eller det materiale, der er til rådighed på tjenesten, og må f.eks. ikke distribuere, videresende eller kopiere materialet til andre eller til andre hjemmesider eller gøre kommerciel/erhvervsmæssig brug af materialet. Brugerens bolig må dog overdrages til en anden bruger, gennem funktionen “Overdrag bolig” under kontoindstillinger.</p>
                            <p>Tjenesten er gratis at benytte sig af, men Onbo ApS forbeholder sig retten til, til enhver tid, at tilføje betalingskrav for hele tjenesten eller enkelte funktioner. Dette skal varsles til brugeren på mail en måned inden ændring, for at give denne mulighed for at oplyse om betalingsinformation eller slette sin brugeren inden betalingskravet træder i kraft.</p>
                          </Tab.Pane>
                          <Tab.Pane eventKey="5">
                            <p>Vi har brug for følgende oplysninger, når du benytter vores service:</p>
                            <p>Navn, email, fulde adresse herunder; vejnavn, vejnummer, postnummer og kommune og data fra BBR/OIS</p>
                            <p>Vi registrerer og videregiver de personoplysninger, som er nødvendige for at kunne levere vores service. Når vi har fundet den rigtige samarbejdspartner til din opgave, vil dine personoplysninger blive videregivet til den pågældende virksomhed ift. den videre proces.</p> 
                            <p>Vi samarbejder herudover med en række andre virksomheder, som opbevarer og behandler data. Virksomhederne behandler udelukkende oplysninger på vores vegne og må ikke bruge dem til egne formål. Vi samarbejder kun med databehandlere i EU eller i lande, der kan give dine oplysninger en tilstrækkelig beskyttelse. Den dataansvarlige på Onbo.dk er Onbo ApS.</p>
                            <p>Ved oprettelse af profil og upload af materiale accepterer brugeren, at disse oplysninger og materiale gøres tilgængelig for samarbejdspartnere og for Onbo ApS. Brugeren accepterer, at Onbo ApS samarbejdspartnere får ret til at bruge dette materiale, som brugeren har uploadet samt oplyst.</p>
                            <p>BBrugeren kan til enhver tid selv redigere eller slette oplysninger og uploadet materiale. Onbo ApS iagttager oplysningsforpligtelsen overfor brugeren i medfør af Databeskyttelsesforordningens artikel 13, stk. 1 og 2. I den forbindelse informerer Onbo ApS desuden brugeren om, at samarbejdspartnere og Onbo ApS som udgangspunkt opbevarer brugerens personoplysninger indtil denne sletter sin bruger, eller hvis brugeren er inaktiv og boligen bliver overdraget til anden bruger, hvorefter oplysningerne slettes. Hvis samarbejdspartneren opbevarer brugerens personoplysninger i længere tid, påhviler det samarbejdspartnere at informere brugerne herom. Samarbejdspartneren behandler brugerens oplysninger med henblik på at kunne indgå aftale med brugerne. Samarbejdspartnerens behandlingsgrundlag herfor er databeskyttelsesforordningens artikel 6, stk. 1, litra b. Hvis samarbejdspartneren behandler brugerens oplysninger til andre formål, påhviler det samarbejdspartneren at informere brugeren herom, i henhold til Databeskyttelsesforordningens artikel 14, stk. 4.</p>
                            <p>Du har ret til at få oplyst, hvilke oplysninger vi behandler om dig. Hvis du mener, at oplysningerne er unøjagtige, har du ret til at få dem rettet. I nogle tilfælde har vi pligt til at slette dine persondata, hvis du beder om det. Det kan f.eks. være hvis dine data ikke længere er nødvendige i forhold til det formål, som vi skulle bruge dem til. Du kan også kontakte os, hvis du mener, at dine persondata bliver behandlet i strid med lovgivningen. Du kan skrive til os på: kontakt@onbo.dk.</p>
                          </Tab.Pane>
                          <Tab.Pane eventKey="6">
                            <p>Brugeren kan til enhver tid vælge selv at slette sin profil. Ved sletning af profilen skal brugeren være opmærksom på, at evt. cases på sitet og beskeder sendt til andre fortsat vil være tilgængelig for modtagerne, og disse cases og beskeder vil ikke blive slettet. Ydermere vil data om boligen, uploadet af brugeren, forsat være tilgængelig for den næste bruger der overtager boligen. Onbo ApS kan til enhver tid, slette eller deaktivere en brugers profil. Onbo ApS forbeholder sig endvidere retten til - uden varsel - at ophøre med at udbyde tjenesten og dermed at slette profilen. Ønsker en bruger at slette sin profil, skal dette gøres ved at logge ind på platformen. Herefter klikkes der ind under Kontoindstillinger under brugerens navn i øverste højre hjørne. I bunden af denne side har brugeren mulighed for at slette sin profil, ved at følge vejledningen på skærmen.</p>                
                          </Tab.Pane>
                          <Tab.Pane eventKey="7">
                            <p>Onbo ApS forbeholder sig ret til på ethvert tidspunkt at ændre disse vilkår. De, til enhver tid gældende betingelser, vil være tilgængelige på tjenesten.</p>
                          </Tab.Pane>
                          <Tab.Pane eventKey="8">
                            <p>Brugerens profil er personlig, og kan ikke overdrages til andre uden Onbo ApS samtykke. Det er dog muligt at overdrage ens bolig-profil gennem funktionen “Overdrag bolig” under kontoindstillinger.</p>
                            <p>Onbo ApS forbeholder sig ret til at overdrage tjenesten samt rettigheder helt eller delvist til tredjemand. Skulle en sådan overdragelse finde sted, modtager brugeren meddelelse herom med en frist til at slette sin profil, såfremt brugeren ikke accepterer overdragelsen.</p>
                          </Tab.Pane>
                          <Tab.Pane eventKey="9">
                            <p>Nærværende vilkår og brugen af tjenesten er underlagt dansk ret, bortset fra internationalt privatretlige regler, medmindre andet følger af ufravigelig lovgivning. Evt. tvister afgøres af de danske domstole.</p>
                          </Tab.Pane>
                          <Tab.Pane eventKey="10">
                            <p>Når du besøger en hjemmeside, gemmes der data på din computer. Man bruger cookies til at huske dig, hvis du vender tilbage på hjemmesiden og til at gemme din færden, mens du er på hjemmesiden. Man bruger de oplysninger, som registreres, til fx statistik og i høj grad til at forbedre din brugeroplevelse. Cookies kan ikke indeholde virus da det ikke er et program.</p>
                            <br /><br />
                            <h5>Vi anvender cookies således</h5>
                            <p>På Onbo.dk bruger vi cookies til at optimere og forbedre vores hjemmeside, for at gøre besøget let og anvendeligt for dig.</p>
                            <p>Tjenesten gemmer cookies for opretholdelse af essentielle funktioner. Dette betyder at der bliver gemt cookies på din computer, som er nødvendige for at opretholde tjenestens funktioner, og som efter endt session automatisk vil blive slettet.</p>
                            <p>Vi bruger cookies til at forbedre vores hjemmesider og markedsføring ved at indsamle statistiske og marketing cookies. Statistiske cookies hjælper os med at forstå, hvordan vores besøgende interagerer med vores hjemmesider, mens marketingcookies hjælper os med at sende relevante annoncer og kampagner på tværs af hjemmesider til de brugere, der har vist interesse i vores services. </p>
                            <p>Vi indsamler information om dine besøg på vores hjemmesider, herunder hvornår og hvor lang tid du er på hjemmesiden, hvilke sider du besøger, og hvilke services du er mest interesseret i. Vi bruger også dine browseroplysninger, IP-adresse, samt hvilken enheder du tilgår hjemmesiderne fra, til at optimere vores annoncer og bannerreklamer til dig. </p>
                            <p>Nogle cookies vil blive lagret over længere perioder, mens andre kun vil blive indsamlet, mens du bruger vores tjeneste.</p>
                          </Tab.Pane>
                        </Tab.Content>
                      </Col>
                    </Row>
                  </Tab.Container>
                  <br /><br /><br />
                  <h3 className='herotitleLegal'><i className="bi bi-dot"></i>Klagevejledning</h3>
                  <br />
                  <p>
                    Har du en klage over et produkt, kan denne sendes til den ansvarlige myndighed:
                    <br /><br />
                    <strong>Datatilsynet</strong>
                  </p>
                  <ul>                    
                    <li>Carl Jacobsens Vej 35, </li>
                    <li>2500 Valby</li>
                    <li>Tlf: 33 19 32 00</li>
                    <li>dt@datatilsynet.dk</li>
                  </ul>                    
                  <p>
                    Se mere på <a href='https://www.datatilsynet.dk' target="_blank" rel="noopener noreferrer">www.datatilsynet.dk</a>
                    <br />
                    <br />
                    Hvis du er forbruger med bopæl i et andet EU-land, kan du angive din klage i EU Kommissionens online klageplatform.
                    Platformen findes her: http://ec.europa.eu/consumers/odr/
                    Angiver du en klage her, skal du oplyse vores E-mail adresse: kontakt@onbo.dk
                  </p>
                  <br /><br /><br />
                </div>
            </div>
        </div>
    </>
  )
}

export default Legal
import React from "react";
import { Carousel, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
/* import CalculationForm from './CalculationForm'; */
import "./CalculationForm.css";
import "./Ejendomsvurdering.css";
import { Helmet } from "react-helmet-async";
import CookieModal from "../CookieModal";

function Ejendomsvurdering() {
  return (
    <>
      <Helmet>
        <title>
          Nye offentlige ejendomsvurderinger | Onbo - Din online bolig
        </title>
        <meta
          name="description"
          content="Den nye offentlige ejendomsvurdering, der træder i kraft i 2024, vil have stor betydning for boligejere i Danmark. Ejendomsvurderingen fastlægger, hvor meget din bolig er værd, og er afgørende for, hvor meget du skal betale i ejendomsværdiskat. Det er derfor vigtigt at have styr på, hvordan vurderingen foretages, og hvordan den påvirker din økonomi. "
        />
        <meta
          name="keywords"
          content="ejendomsvurdering, offentlig ejendomsvurdering, den offentlige ejendomsvurdering, ny ejendomsvurderingslov, offentlig vurdering"
        />
      </Helmet>
      <CookieModal />
      <div className="headerNavBackground"></div>
      <header className="mastheadEjendomsvurdering">
        <div className="row">
          <div className="col-1"></div>
          <div className="col-xl-6 col-lg-8 col-sm-10 justify-content-start text-start">
            <br />
            <h1 className="herotitleHowWork">
              Nye offentlige ejendomsvurderinger
            </h1>
            <p className="heroSubheadingHowWork text-white">
              Læs og forstå hvilken betydning de nye offentlige
              ejendomsvurderinger har for lige netop din bolig
            </p>
            <br />
          </div>
        </div>
      </header>

      <Row className="justify-content-center align-items-center">
        <div className="col-10">
          <p className=" mt-5 pb-5 mb-5">
            Den nye offentlige ejendomsvurdering, der træder i kraft i 2024, vil
            have stor betydning for boligejere i Danmark. Ejendomsvurderingen
            fastlægger, hvor meget din bolig er værd, og er afgørende for, hvor
            meget du skal betale i ejendomsværdiskat
            <OverlayTrigger
              overlay={
                <Tooltip id="vurd_tooltip">
                  Skatten beregnes på baggrund af ejendommens værdi og betales
                  af ejeren af ejendommen. Den nye ejendomsvurdering vil have
                  betydning for, hvor meget ejendomsværdiskat, man skal betale
                </Tooltip>
              }
            >
              <span className="d-inline-block">
                <i
                  style={{
                    color: "blue",
                    fontSize: "70%",
                    verticalAlign: "top",
                  }}
                  className="bi bi-question-circle-fill"
                ></i>
              </span>
            </OverlayTrigger>
            . Det er derfor vigtigt at have styr på, hvordan vurderingen
            foretages, og hvordan den påvirker din økonomi.
            <br />
            <br />
            Ejendomsvurderingen tager udgangspunkt i en række faktorer, herunder
            boligens størrelse, beliggenhed og stand. Den nye vurdering vil være
            mere nuanceret og præcis end tidligere, og det betyder, at nogle
            boligejere vil opleve, at deres boligværdi falder, mens andre vil
            opleve, at den stiger.
            <br />
            <br />
            Det er vigtigt at have styr på din ejendomsvurdering, da den er
            afgørende for, hvor meget du skal betale i ejendomsværdiskat. Hvis
            din bolig er vurderet for højt, kan du ende med at betale for meget
            i skat, mens en for lav vurdering kan betyde, at du betaler for
            lidt. Derfor er det vigtigt at have styr på, hvordan din
            ejendomsvurdering påvirker din økonomi, og hvordan du kan klage over
            den, hvis du er uenig. Ydermere vil der også ske ændringer i
            grundskyldssatserne
            <OverlayTrigger
              overlay={
                <Tooltip id="vurd_tooltip">
                  Grundskyld er en skat, som ejerne af grunde og ejendomme
                  betaler til kommunen. Skatten er en afgift på grundens værdi
                  og fastsættes hvert år af kommunen på baggrund af den
                  offentlige ejendomsvurdering.
                </Tooltip>
              }
            >
              <span className="d-inline-block">
                <i
                  style={{
                    color: "blue",
                    fontSize: "70%",
                    verticalAlign: "top",
                  }}
                  className="bi bi-question-circle-fill"
                ></i>
              </span>
            </OverlayTrigger>
            , hvortil din grundværdi er afgørende
            <OverlayTrigger
              overlay={
                <Tooltip id="vurd_tooltip">
                  Den del af ejendommens samlede værdi, der skyldes grundens
                  beliggenhed og størrelse
                </Tooltip>
              }
            >
              <span className="d-inline-block">
                <i
                  style={{
                    color: "blue",
                    fontSize: "70%",
                    verticalAlign: "top",
                  }}
                  className="bi bi-question-circle-fill"
                ></i>
              </span>
            </OverlayTrigger>
            .
          </p>
        </div>
      </Row>
      {/* <Row>
        <CalculationForm />
      </Row> */}
      <Row className="justify-content-center align-items-center">
        <div className="col-11 pt-5 mt-5 text-center">
          <h4 className="herotitleLegal">
            <i className="bi bi-dot"></i>Forklaring af begreber
          </h4>
          <Row className="justify-content-center">
            <p className="col-8">
              Disse begreber er vigtige at have styr på, når man skal forstå,
              hvordan den nye offentlige ejendomsvurdering fungerer, og hvordan
              den påvirker ens økonomi.
            </p>
          </Row>
          {/* <Concepts /> */}
        </div>
      </Row>
      <Row className="justify-content-center align-items-center">
        <div className="col-xl-7 col-lg-8 col-md-10">
          <p className="calcFloatingDesc">Begreber</p>
          <Carousel variant="dark" className="calcMeaningCarousel">
            <Carousel.Item>
              <Row>
                <img
                  className="d-block w-50 calcDescImg mx-auto"
                  src="./images/Ejendomsvurdering/1.jpg"
                  alt="Infografik boligskatteændringer"
                />
              </Row>
              <Row className="justify-content-center align-items-center text-center carousel-text">
                <h5>Opkrævning</h5>
                <p className="w-75">
                  I fremtiden vil alle boligskatter, inklusiv både grundskylden
                  og ejendomsværdiskatten, blive indkrævet gennem
                  forskudsopgørelsen.
                </p>
              </Row>
            </Carousel.Item>
            <Carousel.Item>
              <Row>
                <img
                  className="d-block w-50 calcDescImg mx-auto"
                  src="./images/Ejendomsvurdering/2.jpg"
                  alt="Infografik skatterabatten"
                />
              </Row>
              <Row className="justify-content-center align-items-center text-center carousel-text">
                <h5>Skatterabat</h5>
                <p className="w-75">
                  Boligejere, som har købt deres bolig før 1. januar 2024, vil
                  modtage en permanent skatterabat. Dette betyder, at de ikke
                  vil opleve en stigning i deres skattebetaling, når de overgår
                  til de nye regler. Skatterabatten vil forblive i kraft for
                  boligejeren, indtil boligen sælges.
                </p>
              </Row>
            </Carousel.Item>
            <Carousel.Item>
              <Row>
                <img
                  className="d-block w-50 calcDescImg mx-auto"
                  src="./images/Ejendomsvurdering/3.jpg"
                  alt="Infografik fastlåsning af boligskatterne"
                />
              </Row>
              <Row className="justify-content-center align-items-center text-center carousel-text">
                <h5>Fastlåsning af boligskatten</h5>
                <p className="w-75">
                  Fra 2024 vil boligskatterne blive fastlåst til boligens værdi.
                  Hvis boligprisen stiger, vil boligejerens skattebetaling
                  ligeledes stige.
                </p>
              </Row>
            </Carousel.Item>
            <Carousel.Item>
              <Row>
                <img
                  className="d-block w-50 calcDescImg mx-auto"
                  src="./images/Ejendomsvurdering/4.jpg"
                  alt="Infografik forsigtighedsprincippet"
                />
              </Row>
              <Row className="justify-content-center align-items-center text-center carousel-text">
                <h5>Forsigtighedsprincippet</h5>
                <p className="w-75">
                  Ved udarbejdelsen af din nye ejendomsvurdering vil
                  skattevæsnet reducerer den anslåede værdi med 20 procent for
                  at tage højde for usikkerheder.
                </p>
              </Row>
            </Carousel.Item>
            <Carousel.Item>
              <Row>
                <img
                  className="d-block w-50 calcDescImg mx-auto"
                  src="./images/Ejendomsvurdering/5.jpg"
                  alt="Infografik indefrosset boligskat"
                />
              </Row>
              <Row className="justify-content-center align-items-center text-center carousel-text">
                <h5>Indefrosset boligskat</h5>
                <p className="w-75">
                  Ejerboliger vil blive vurderet hvert andet år efter 2024. Hvis
                  værdiansættelsen fortsat stiger i de følgende år, vil det være
                  muligt at fryse de nye stigninger i boligskatten, indtil
                  boligen bliver solgt.
                </p>
              </Row>
            </Carousel.Item>
            <Carousel.Item>
              <Row>
                <img
                  className="d-block w-50 calcDescImg mx-auto"
                  src="./images/Ejendomsvurdering/6.jpg"
                  alt="Infografik reduktion af ejendomsværdiskatten"
                />
              </Row>
              <Row className="justify-content-center align-items-center text-center carousel-text">
                <h5>Reduktion af ejendomsværdiskatten</h5>
                <p className="w-75">
                  Det forventes, at satsen for ejendomsværdiskat vil blive
                  reduceret fra 0,92 procent til 0,51 procent. Ejendomme med en
                  værdi over 10 millioner kroner vil blive beskattet med 1,4
                  procent. Dette er en forsøg på at sikre, at den samlede
                  skatteindtægt for statskassen forbliver omtrent uændret.
                </p>
              </Row>
            </Carousel.Item>
          </Carousel>
        </div>
      </Row>
      <Row className="justify-content-center align-items-center text-center pt-5">
        <div className="col-8">
          <br />
          <br />
          <p>Gør onbo til samlingssted for alt din ejendomsdata.</p>
        </div>
      </Row>
      <Row className="justify-content-center align-items-center text-center pb-5 mb-5">
        {/* <Button id='cta_createuser_btn_1' href='#/signup' className='col-xl-2 col-lg-2 col-md-3 col-sm-5 col-7 startToday-btn'>Start i dag</Button> */}
      </Row>
    </>
  );
}

export default Ejendomsvurdering;

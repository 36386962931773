import React, { useState } from 'react'
import { Card, Button } from 'react-bootstrap'
import "./CardNavigation.css"
import { Link } from 'react-router-dom';


function CardNavigation(props) {  
  const [bgColour, setBgColour] = useState("#fff");
  const [textColor, setTextColor] = useState("#000");
  const [btnShadow, setBtnShadow] = useState("none");

  /* Styles */
  const appStyles = {
    background: `${bgColour}`,
    color: `${textColor}`,
    boxShadow: `${btnShadow}`,
  };

  function changeStylesEnter() {
    setBgColour("rgba(23, 12, 120, 0.9)");
    setTextColor("#EDFEB2");
    setBtnShadow("0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19)");
  }

  function changeStylesLeave() {
    setBgColour("#fff");
    setTextColor("#000");
    setBtnShadow("none");
  }

  return (  
    <Link to={props.cardLink}>
      <Card className="text-black " id='navCards'
      onMouseEnter={changeStylesEnter}
      onMouseLeave={changeStylesLeave}
      style={{backgroundImage:  "radial-gradient(circle, rgba(0, 0, 0, 0.3) 60%, rgba(0, 0, 0, .2) 100%), url(" + props.cardBackground + ")" }}
      >        
          <Card.ImgOverlay className='row cardBody text-center justify-content-center align-items-center'>
            <Button style={appStyles} className='buttonCard' variant="primary">{props.buttonText}</Button>
          </Card.ImgOverlay>
        </Card>
      </Link>
  )
}

export default CardNavigation
import React from 'react'
import "./TeamMember.css";
import { Button } from "react-bootstrap"

function TeamMember(props) {
  return (
    <div className='team-member'>
        <p style={{fontSize: "120%"}} className='text-muted'>{props.teamPosition}</p>
        <img className='mx-auto teamImage rounded-circle mb-3' src={props.teamImg} alt={props.teamName} />
        <h4>{props.teamName}</h4>
        <Button href={props.teamLinkedin} className='btn btn-dark btn-contactTeam mx-2'><i className="bi bi-linkedin"></i></Button>
        <Button href={props.teamPhone} className='btn btn-dark btn-contactTeam mx-2'><i className="bi bi-telephone-outbound-fill"></i></Button>
        <Button href={props.teamMail} className='btn btn-dark btn-contactTeam mx-2'><i className="bi bi-envelope-heart-fill"></i></Button>
    </div>
  )
}

export default TeamMember
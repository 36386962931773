import React from 'react'
import Hero404 from './Hero404'
import "./NotFound404.css"

function NotFound404() {
  return (
    <>
      <Hero404 />
    </>
  )
}

export default NotFound404
import React, {useState, useEffect} from 'react'
import { Modal, Tabs, Tab, Button, Row, ButtonGroup } from 'react-bootstrap'
import "./CookieModal.css"
import { Link } from 'react-router-dom';
import Cookies from "universal-cookie";
const cookies = new Cookies();

function CookieModal() {
    const [show, setShow] = useState(false);
    const CookiePreference = cookies.get("CookiePreference");

    useEffect(() => {
        if(CookiePreference === "essential" || CookiePreference === "all") {
            
        } else {
            setTimeout(function() {
                setShow(true);
            }, 2000);
        }
    }, [CookiePreference])


    const handleCloseEssential = () => {
        setShow(false);
        cookies.set("CookiePreference", "essential", {
            path: "/",
        });
        window.location.reload();
    }

    const handleCloseAll = () => {
        setShow(false);
        cookies.set("CookiePreference", "all", {
            path: "/",
        });
        window.location.reload();
    }


  return (
    <Modal
        show={show}
        backdrop="static"
        keyboard={false}
        className='cookieModal'
        centered
        size='lg'
      >
        <Modal.Body>
            <img className='img-fluid cookie-logo' src="/images/Logos/Logo_black.svg" alt="" />
            <br /><br />
            <Tabs className='cookie-tabs' fill variant="pills" defaultActiveKey="home">
                <Tab eventKey="home" title="Samtykke">
                    <div className='cookie-text-p'>
                    <p><i style={{color: "blue", fontSize: "110%"}} className="bi bi-dot"></i>På denne hjemmeside bruger vi cookies</p>
                    <p>
                    For at kunne benytte denne hjemmeside, kræver det at du accepterer nogle basale cookies. Siden virker simpeltehen ikke uden.
                    For at give dig og andre en bedre oplevelse, vil vi også spørge om tilladelse til at gemme 3. parts cookies?
                    </p>
                    <p>Læs mere om vores privatlivspolitik  <Link to="/legal">her</Link></p>
                    </div>
                </Tab>
                <Tab eventKey="x" title="Detaljer">
                    <p className='cookie-text-p'>
                    På Onbo.dk bruger vi cookies til at optimere og forbedre vores hjemmeside, for at gøre besøget let og anvendeligt for dig.
                    <br /><br />
                    Tjenesten gemmer cookies for opretholdelse af essentielle funktioner. Dette betyder at der bliver gemt cookies på din computer, som er nødvendige for at opretholde tjenestens funktioner, og som efter endt session automatisk vil blive slettet.
                    Vi bruger cookies til at forbedre vores hjemmesider og markedsføring ved at indsamle statistiske og marketing cookies. Statistiske cookies hjælper os med at forstå, hvordan vores besøgende interagerer med vores hjemmesider, mens marketingcookies hjælper os med at sende relevante annoncer og kampagner på tværs af hjemmesider til de brugere, der har vist interesse i vores services.
                    <br /><br />
                    Vi indsamler information om dine besøg på vores hjemmesider, herunder hvornår og hvor lang tid du er på hjemmesiden, hvilke sider du besøger, og hvilke services du er mest interesseret i. Vi bruger også dine browseroplysninger, IP-adresse, samt hvilken enheder du tilgår hjemmesiderne fra, til at optimere vores annoncer og bannerreklamer til dig.
                    <br /><br />
                    Nogle cookies vil blive lagret over længere perioder, mens andre kun vil blive indsamlet, mens du bruger vores tjeneste.
                    </p>
                </Tab>
            </Tabs>
            <br />
            <Row className='justify-content-center align-items-center'>
                <ButtonGroup className='cookie-consent-btngroup'>
                    <Button className='cookie-consent-essential' onClick={handleCloseEssential}>Kun nødvendige</Button>
                    <Button className='cookie-consent-all' onClick={handleCloseAll}>Accepter alle</Button>
                </ButtonGroup>
            </Row>
        </Modal.Body>
      </Modal>
  )
}

export default CookieModal
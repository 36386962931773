import React, { useEffect, useState } from 'react'
import HeroHowWork from './HeroHowWork'
import CookieModal from '../CookieModal'
import { Button, Modal, Row } from 'react-bootstrap'
import { Helmet } from 'react-helmet-async';
import "./HowItWorks.css"

function HowItWorks() {
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setViewportWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    // cleanup function to remove the event listener when component unmounts
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [function1, setFunction1] = useState(true);
  const [function2, setFunction2] = useState(false);
  const [function3, setFunction3] = useState(false);
  const [function4, setFunction4] = useState(false);
  const [function5, setFunction5] = useState(false);

  const changeFunctionShow1 = (e) => {
    setFunction1(true)
    setFunction2(false)
    setFunction3(false)
    setFunction4(false)
    setFunction5(false)
    if(function2 || function3 || function4 || function5  === true) {
      scrollLeft();
    } else {
      scrollRight();
    }
  }
  const changeFunctionShow2 = (e) => {
    setFunction1(false)
    setFunction2(true)
    setFunction3(false)
    setFunction4(false)
    setFunction5(false)
    if(function3 || function4 || function5  === true) {
      scrollLeft();
    } else {
      scrollRight();
    }
  }
  const changeFunctionShow3 = (e) => {
    setFunction1(false)
    setFunction2(false)
    setFunction3(true)
    setFunction4(false)
    setFunction5(false)
    if(function4 || function5  === true) {
      scrollLeft();
    } else {
      scrollRight();
    }
  }
  const changeFunctionShow4 = (e) => {
    setFunction1(false)
    setFunction2(false)
    setFunction3(false)
    setFunction4(true)
    setFunction5(false)
    if(function5  === true) {
      scrollLeft();
    } else {
      scrollRight();
    }
  }
  const changeFunctionShow5 = (e) => {
    setFunction1(false)
    setFunction2(false)
    setFunction3(false)
    setFunction4(false)
    setFunction5(true)
    scrollRight();
  }


  const [showMore1, setShowMore1] = useState(false);
  const [showMore2, setShowMore2] = useState(false);
  const [showMore3, setShowMore3] = useState(false);
  const [showMore4, setShowMore4] = useState(false);
  const [showMore5, setShowMore5] = useState(false);

  const text1 = "Med Onbo kan du nemt holde styr på vedligeholdelse, reparationer, og alle andre vigtige oplysninger om din bolig. Vi tilbyder dig en omfattende platform, der hjælper dig med at holde overblik på alt fra vedligehold og dokumentation til økonomi og forbedringer, så du altid er i kontrol."; 
  const text2 = "Noget som alle boligejere har tilfælles er, at vi gerne vil holde vores bolig i god stand. Med Onbo's vedligeholdelsesplan kan du forebygge skader og forlænge levetiden på din bolig. En plan kan omfatte alt fra regelmæssig rengøring og vedligeholdelse af tag, facade og vinduer til service af varmeanlæg, vandinstallationer og elinstallationer."; 
  const text3 = "Som boligejer i Danmark er der mange dokumenter at finde og holde styr på. Opbevar dem alle ved os. Om det er kvitteringer, servitutter, byggesager, tilstandsrapporter eller andet, kan du nemt finde dem på platformen. På den måde sparer du tid og ressourcer på at lede efter dem, når du skal bruge dem. Evt. ved overdragelse til ny ejer, eller hvis du står og skal lave en ny lækker tilbygning.";
  const text4 = "Især som førstegangskøber, kan det være overvældende hvor mange ting der skal laves i ens bolig - både ved forskellige årstider, mens også hvis du ønsker at forbedre din bolig, eller sikre at den beholder samme kvalitet. Lad os hjælpe dig med råd og vejledning i, hvad der er fordelagtigt at have styr på i løbet af året, for at sikre at intet bliver forsømt.";
  const text5 = "Den nye offentlige ejendomsvurdering, der træder i kraft i 2024, vil have stor betydning for boligejere i Danmark. Ejendomsvurderingen fastlægger, hvor meget din bolig er værd, og er afgørende for, hvor meget du skal betale i ejendomsværdiskat. Det er derfor vigtigt at have styr på, hvordan vurderingen foretages, og hvordan den påvirker din økonomi. Dette kan vi give et estimat på.";

  function scrollRight() {
    const element = document.getElementById("scroll-wrapper");
    if(viewportWidth >=500) {
      element.scrollLeft += 400;
    }
    if(viewportWidth <= 500) {
      element.scrollLeft += 350;
    }
    if(viewportWidth <=450) {
      element.scrollLeft += 300;
    }
    if(viewportWidth <=400) {
      element.scrollLeft += 275;
    }
  }
  function scrollLeft() {
    const element = document.getElementById("scroll-wrapper");
    if(viewportWidth >=500) {
      element.scrollLeft -= 400;
    }
    if(viewportWidth <= 500) {
      element.scrollLeft -= 350;
    }
    if(viewportWidth <=450) {
      element.scrollLeft -= 300;
    }
    if(viewportWidth <=400) {
      element.scrollLeft -= 275;
    }
  }

  function scrollLeftArrow() {
    if(function2 === true) {
      changeFunctionShow1()
    }
    if(function3 === true) {
      changeFunctionShow2()
    }
    if(function4 === true) {
      changeFunctionShow3()
    }
    if(function5 === true) {
      changeFunctionShow4()
    }
  }
  function scrollRightArrow() {
    if(function1 === true) {
      changeFunctionShow2()
    }
    if(function2 === true) {
      changeFunctionShow3()
    }
    if(function3 === true) {
      changeFunctionShow4()
    }
    if(function4 === true) {
      changeFunctionShow5()
    }
  }

  return (
    <>
      <Helmet>
        <title>Funktioner | Onbo - Din online bolig</title>
        <meta name='description' content='Som boligejer i Danmark, er der meget at holde styr på. Dette vil vi hos Onbo hjælpe dig med. Vi tilbyder værktøjer, som gør det nemmere for dig at være boligejer, ved at hjælpe dig med at skabe overblik omkring din bolig for, at identificere hvad du mangler, og hvor du kan spare penge!' />
        <meta name='keywords' content='renovering af hus, ombygning af hus, hus renovering, renovering hus, ejendomsdata, ejendomsoplysninger, oplysninger om bolig, ejendomsværdi, boligadministration, administration af bolig, privat bolig, min bolig' />
      </Helmet>
      <CookieModal />
      <HeroHowWork />
      
      <div className='hiw-container-grey mt-5'>
        <div id='scroll-wrapper' className='hiw-tabBtn-wrapper'>
          <Row style={{transition: "all ease 500ms", backgroundColor: function1 ? "white" : "transparent", boxShadow: function1 ? "5px 5px 10px rgba(0,0,0,0.1)" : "none", borderBottom: function1 ? "2px solid rgba(23, 12, 120, 0.9)" : "none"}} onClick={changeFunctionShow1} className="col-xl-5 col-lg-5 col-md-7 col-sm-8 col-10 hiw-tabBtn align-items-center">
            <h6 style={{transition: "all ease 500ms", color: function1 ? "rgba(23, 12, 120, 0.9)" : "black"}}><i style={{transition: "all ease 700ms",fontSize: function1 ? "inherit" : "0"}} className="bi bi-arrow-right-short"></i>Overblik over din bolig</h6>
            <p>Med vores dashboard kan du nemt skabe overblik over aktiviteterne i og omkring din bolig.</p>
          </Row>
          <Row style={{transition: "all ease 500ms", backgroundColor: function2 ? "white" : "transparent", boxShadow: function2 ? "5px 5px 10px rgba(0,0,0,0.1)" : "none", borderBottom: function2 ? "2px solid rgba(23, 12, 120, 0.9)" : "none"}} onClick={changeFunctionShow2} className="col-xl-5 col-lg-5 col-md-7 col-sm-8 col-10 hiw-tabBtn align-items-center">
            <h6 style={{transition: "all ease 500ms", color: function2 ? "rgba(23, 12, 120, 0.9)" : "black"}}><i style={{transition: "all ease 700ms",fontSize: function2 ? "inherit" : "0"}} className="bi bi-arrow-right-short"></i>Vedligeholdelseplan</h6>
            <p>Med Onbo's vedligeholdelsesplan kan du forebygge skader og forlænge levetiden på din bolig.</p>
          </Row>
          <Row id='boligdokumenter' style={{transition: "all ease 500ms", backgroundColor: function3 ? "white" : "transparent", boxShadow: function3 ? "5px 5px 10px rgba(0,0,0,0.1)" : "none", borderBottom: function3 ? "2px solid rgba(23, 12, 120, 0.9)" : "none"}} onClick={changeFunctionShow3} className="col-xl-5 col-lg-5 col-md-7 col-sm-8 col-10 hiw-tabBtn align-items-center">
            <h6 style={{transition: "all ease 500ms", color: function3 ? "rgba(23, 12, 120, 0.9)" : "black"}}><i style={{transition: "all ease 700ms",fontSize: function3 ? "inherit" : "0"}} className="bi bi-arrow-right-short"></i>Alle dokumenter et sted</h6>
            <p>Som boligejer i Danmark er der mange dokumenter at finde og holde styr på. Opbevar dem alle ved os.</p>
          </Row>
          <Row style={{transition: "all ease 500ms", backgroundColor: function4 ? "white" : "transparent", boxShadow: function4 ? "5px 5px 10px rgba(0,0,0,0.1)" : "none", borderBottom: function4 ? "2px solid rgba(23, 12, 120, 0.9)" : "none"}} onClick={changeFunctionShow4} className="col-xl-5 col-lg-5 col-md-7 col-sm-8 col-10 hiw-tabBtn align-items-center">
            <h6 style={{transition: "all ease 500ms", color: function4 ? "rgba(23, 12, 120, 0.9)" : "black"}}><i style={{transition: "all ease 700ms",fontSize: function4 ? "inherit" : "0"}} className="bi bi-arrow-right-short"></i>Gøremål i din bolig</h6>
            <p>Lad os hjælpe dig med råd og vejledning i, hvad der er fordelagtigt at have styr på i løbet af året, for at sikre at intet bliver forsømt.</p>
          </Row>
          <Row style={{transition: "all ease 500ms", backgroundColor: function5 ? "white" : "transparent", boxShadow: function5 ? "5px 5px 10px rgba(0,0,0,0.1)" : "none", borderBottom: function5 ? "2px solid rgba(23, 12, 120, 0.9)" : "none"}} onClick={changeFunctionShow5} className="col-xl-5 col-lg-5 col-md-7 col-sm-8 col-10 hiw-tabBtn align-items-center">
            <h6 style={{transition: "all ease 500ms", color: function5 ? "rgba(23, 12, 120, 0.9)" : "black"}}><i style={{transition: "all ease 700ms",fontSize: function5 ? "inherit" : "0"}} className="bi bi-arrow-right-short"></i>Offentlig ejendomsvurdering</h6>
            <p>Den nye offentlige ejendomsvurdering, der træder i kraft i 2024, vil have stor betydning for boligejere i Danmark. Se den hos os.</p>
          </Row>
        </div>
      </div>
      <Row className='text-center justify-content-center align-items-center'>
          <div onClick={scrollLeftArrow} className="col-1 arrow-left"><i className="bi bi-arrow-left-short"></i></div>
        <div className="col-4">
          <i style={{fontSize: "20px", color: function1 ? "blue" : "grey"}} className="bi bi-dot"></i>
          <i style={{fontSize: "20px", color: function2 ? "blue" : "grey"}} className="bi bi-dot"></i>
          <i style={{fontSize: "20px", color: function3 ? "blue" : "grey"}} className="bi bi-dot"></i>
          <i style={{fontSize: "20px", color: function4 ? "blue" : "grey"}} className="bi bi-dot"></i>
          <i style={{fontSize: "20px", color: function5 ? "blue" : "grey"}} className="bi bi-dot"></i>
        </div>
        <div onClick={scrollRightArrow} className="arrow-right col-1"><i className="bi bi-arrow-right-short"></i></div>
      </Row>
      <div className='hiw-container'>
        <Row style={{height: "40vh", marginBottom: "60px"}} className='justify-content-center align-items-center'>          
          <img style={{width: function1 ? "72%" : "0%", transition: "ease all 750ms"}} src="./images/Infographics/dashboard.jpg" className='infographicImg rounded p-0 m-1 img-fluid  functionImg1' alt="Funktion beskrivelse Onbo" />
          <p style={{display: function1 ? "inline-block" : "none", transition: "ease all 750ms"}} className='col-xl-3 col-lg-6 col-md-6 col-md-10 doc-description-text mt-5 justify-content-center align-items-center'>
          <strong>Overblik over din bolig</strong><br />
          {showMore1 ? text1 : `${text1.substring(0, 150)}`}
          <br />
          <Button  style={{boxShadow: showMore1 ? "none" : "0px 0px 0px rgba(0,0,0,0.05), 0px -10px 10px rgba(255,255,255,1)"}}  className="doc-btn" onClick={() => setShowMore1(!showMore1)}>
            {showMore1 ? "Vis mindre" : "Vis mere"}
          </Button>
          </p>

          <img style={{width: function2 ? "72%" : "0%", transition: "ease all 750ms"}} src="./images/Infographics/vedligehold.jpg" className='infographicImg rounded p-0 m-1 img-fluid functionImg2' alt="Funktion beskrivelse Onbo" />
          <p style={{display: function2 ? "inline-block" : "none", transition: "ease all 750ms"}} className='col-xl-3 col-lg-6 col-md-6 col-md-10 doc-description-text mt-5 justify-content-center align-items-center'>
          <strong>Vedligeholdelseplan</strong><br />
          {showMore2 ? text2 : `${text2.substring(0, 150)}`}
          <br />
          <Button  style={{boxShadow: showMore2 ? "none" : "0px 0px 0px rgba(0,0,0,0.05), 0px -10px 10px rgba(255,255,255,1)"}}  className="doc-btn" onClick={() => setShowMore2(!showMore2)}>
            {showMore2 ? "Vis mindre" : "Vis mere"}
          </Button>
          </p>

          <img style={{width: function3 ? "72%" : "0%", transition: "ease all 750ms"}} src="./images/Infographics/dokumenter.jpg" className='infographicImg rounded p-0 m-1 img-fluid functionImg3' alt="Funktion beskrivelse Onbo" />
          <p style={{display: function3 ? "inline-block" : "none", transition: "ease all 750ms"}} className='col-xl-3 col-lg-6 col-md-6 col-md-10 doc-description-text mt-5 justify-content-center align-items-center'>
          <strong>Alle dokumenter et sted</strong><br />
          {showMore3 ? text3 : `${text3.substring(0, 150)}`}
          <br />
          <Button  style={{boxShadow: showMore3 ? "none" : "0px 0px 0px rgba(0,0,0,0.05), 0px -10px 10px rgba(255,255,255,1)"}}  className="doc-btn" onClick={() => setShowMore3(!showMore3)}>
            {showMore3 ? "Vis mindre" : "Vis mere"}
          </Button>
          </p>

          <img style={{width: function4 ? "72%" : "0%", transition: "ease all 750ms"}} src="./images/Infographics/todo.jpg" className='infographicImg rounded p-0 m-1 img-fluid functionImg4' alt="Funktion beskrivelse Onbo" />
          <p style={{display: function4 ? "inline-block" : "none", transition: "ease all 750ms"}} className='col-xl-3 col-lg-6 col-md-6 col-md-10 doc-description-text mt-5 justify-content-center align-items-center'>
          <strong>Gøremål i din bolig</strong><br />
          {showMore4 ? text4 : `${text4.substring(0, 150)}`}
          <br />
          <Button  style={{boxShadow: showMore4 ? "none" : "0px 0px 0px rgba(0,0,0,0.05), 0px -10px 10px rgba(255,255,255,1)"}}  className="doc-btn" onClick={() => setShowMore4(!showMore4)}>
            {showMore4 ? "Vis mindre" : "Vis mere"}
          </Button>
          </p>

          <img style={{width: function5 ? "72%" : "0%", transition: "ease all 750ms", }} src="./images/Infographics/ejendomsvurdering.jpg" className='infographicImg rounded p-0 m-1 img-fluid functionImg5' alt="Funktion beskrivelse Onbo" />
          <p style={{display: function5 ? "inline-block" : "none", transition: "ease all 750ms"}} className='col-xl-3 col-lg-6 col-md-6 col-md-10 doc-description-text mt-5 justify-content-center align-items-center'>
          <strong>Offentlig ejendomsvurdering</strong><br />
          {showMore5 ? text5 : `${text5.substring(0, 150)}`}
          <br />
          <Button  style={{boxShadow: showMore5 ? "none" : "0px 0px 0px rgba(0,0,0,0.05), 0px -10px 10px rgba(255,255,255,1)"}}  className="doc-btn" onClick={() => setShowMore5(!showMore5)}>
            {showMore5 ? "Vis mindre" : "Vis mere"}
          </Button>
          </p>
        </Row>
      </div>

    
      <div className='spacing'></div>

      <div className='hiw-container hiw-spacing-1 mt-5 pt-5'>
        <Row className='justify-content-center align-items-center text-center'>
          <h3>Alle ejendomsoplysninger samlet et et sted</h3>
          <br />
          <br />
          <br />
          <p className='w-75'>Vi tilbyder værktøjer, som gør det nemmere for dig at være boligejer. Vi ønsker at hjælpe dig med at skabe overblik omkring din bolig for, at identificere hvad du mangler, og hvor du kan spare penge!</p>
        </Row>
        <Row className='justify-content-center align-items-center text-center'>
          {/* <Button id='cta_createuser_btn_1' href='#/signup' className='col-xl-2 col-lg-2 col-md-3 col-sm-5 col-7 startToday-btn'>Start i dag</Button> */}
        </Row>
      </div>

 {/*      <Row className="mt-5 mb-5 justify-content-center align-items-center hiw-video-wrapper">
          <video className='hiw-video' autoPlay muted loop>
            <source className='' controls src="./images/Infographics/hej.mp4" type="video/mp4" />
          </video>
      </Row>
       */}
      <div className='hiw-container mb-5'>
        <Row className='justify-content-center align-items-center mb-5'>
          <div className="col-xl-6 col-lg-6 col-md-7 col-12 p-5">
            <p style={{textTransform: "uppercase", fontSize: "120%"}}>Tilføj Onbo som webapp</p>
            <h4>Med vores platform, har du altid din bolig lige ved hånden</h4>
            <p>I bunden af mobil browseren skal du trykke på deleikonet på menulinjen - det ligner en boks med en pil op. Vælg Føj til startskærm på listen over delingsmuligheder.  Giv det et navn evt. “Onbo”, og tryk "tilføj". Så nemt får du onbo som webapp: <span onClick={handleShow} className='video-clickhere'> se her!</span></p>
            <Row className='justify-content-center align-items-center'>
                <i className="bi bi-box-arrow-up hiw-rounded-btn"></i>
                <i className="bi bi-caret-right-fill hiw-arrow"></i>
                <i className="bi bi-plus-square hiw-rounded-btn"></i>
                <i className="bi bi-caret-right-fill hiw-arrow"></i>
                <i className='hiw-rounded-btn'>Tilføj</i>
            </Row>            
          </div>
          <div className="col-xl-5 col-lg-5 col-md-4 col-10">
            <img src="./images/Infographics/phone-user.jpg" className='infographicImg rounded w-100 p-0 img-fluid' alt="By wayhomestudio on Freepik" />
          </div>
        </Row>
      </div>
      <Modal onClick={handleClose} className='hiw-video-modal text-center justify-content-center align-items-center' centered show={show} onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>
        <video loop autoPlay className='hiw-info-video' src="videos/Add_app_video.mp4"></video>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  )
}

export default HowItWorks
import React from 'react'
import CookieModal from '../CookieModal'
import "./Signup.css"
import SignupForm from './SignupForm'

function Signup() {
  return (
    <>
    <CookieModal />
    <div className='row'>
        <div className="col-xl-6 col-lg-8 signupContainer">
            <SignupForm />
        </div>
        <div className="signupImg col-xl-6 col-lg-4 d-none d-lg-block"></div>
    </div>
    </>
  )
}


export default Signup